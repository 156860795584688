<template lang='pug'>
.product-payments
  .row
    .col
      fi-form-field(
        :label     = '$t("paymentsEnabled")'
        label-cols = 3
      )
        fi-switch(v-model = 'productData.outgoingPaymentsEnabled')

      fi-form-field(
        :label     = '$t("portalOutgoingPayments")'
        label-cols = 3
      )
        fi-switch(v-model = 'productData.portalOutgoingPaymentsEnabled')

      fi-form-field(
        :label     = '$t("boThreshold")'
        label-cols = '3'
      )
        fi-money-input.form-control.form-control-sm(v-model = 'productData.outgoingPaymentConformationNeededFromSum')

      fi-form-field(
        v-if       = '!deposit'
        :label     = '$t("hold")'
        label-cols = '3'
      )
        input.form-control.form-control-sm(v-model = 'productData.outgoingPaymentDelayHours')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { mapGetters } from 'vuex'

export default {
  name: 'product-payments',

  components: { FiSwitch, FiSelect, FiMoneyInput, FiFormField },

  props: {
    productData: {
      type: Object,
      required: true
    },
    deposit: {
      type: Boolean,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier'])
  }
}
</script>


<i18n>
en:
  boThreshold:            "BO payment confirmation threshold"
  hold:                   "On hold payments (hours)"
  paymentsEnabled:        "Outgoing payments enabled"
  portalOutgoingPayments: "Payout from portal"
et:
  boThreshold:            "BO payment confirmation threshold"
  hold:                   "On hold payments (hours)"
  paymentsEnabled:        "Outgoing payments enabled"
  portalOutgoingPayments: ""
ru:
  boThreshold:            "BO payment confirmation threshold"
  hold:                   "On hold payments (hours)"
  paymentsEnabled:        "Outgoing payments enabled"
  portalOutgoingPayments: ""
</i18n>
