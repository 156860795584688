<template lang='pug'>
.product-attributes.table-responsive.mb-0
  table.table
    thead: tr
      th {{ $t('fieldName') }}
      th {{ $t('fieldValue') }}
      th {{ $t('changesAllowed') }}
    tbody
      tr
        td {{ $t('period') }}
        td: fi-form-field.mb-0(:state = '!validationState.periodGroup.$anyError')
          template(#error)
            .row
              .col-md-4
                .error-message(v-if = '!validationState.periodUnitTypeId.required') {{ $t('common:required') }}
              .col-md
                .error-message(v-if = '!validationState.period.min') {{ $t('minError', { value: validationState.periodMin.$model }) }}
                .error-message(v-if = '!validationState.period.max') {{ $t('maxError', { value: validationState.periodMax.$model }) }}
                .error-message(v-if = '!validationState.period.required') {{ $t('common:required') }}
              .col-md
                .error-message(v-if = '!validationState.periodMin.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.periodMin.min') {{ $t('minError', { value: validationState.periodMin.$params.min.min }) }}
                .error-message(v-if = '!validationState.periodMin.max') {{ $t('maxError', { value: validationState.periodMax.$model }) }}
              .col-md
                .error-message(v-if = '!validationState.periodMax.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.periodMax.min') {{ $t('minError', { value: validationState.periodMin.$model }) }}
          .row
            .col-md
              fi-select(
                v-model.number = 'validationState.periodUnitTypeId.$model'
                :options     = 'periodUnitOptions'
                :class         = '{ "is-invalid": validationState.periodUnitTypeId.$error }'
                required
                sm
              )
            .input-group.col.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:default') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.period.$model'
                :class         = '{ "is-invalid": validationState.period.$error }'
              )
            .input-group.col-md.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:min') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.periodMin.$model'
                :class         = '{ "is-invalid": validationState.periodMin.$error }'
              )
            .input-group.col-md.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:max') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.periodMax.$model'
                :class         = '{ "is-invalid": validationState.periodMax.$error }'
              )
            .col-md
        td: fi-switch(v-model = 'productData.periodOverride')
      tr
        td {{ $t('amount') }}
        td: fi-form-field.mb-0(:state = '!validationState.amountGroup.$anyError')
          template(#error)
            .row
              .col-md
                .error-message(v-if = '!validationState.currencyId.required') {{ $t('common:required') }}
              .col-md
                .error-message(v-if = '!validationState.amount.min') {{ $t('minError', { value: validationState.amountMin.$model }) }}
                .error-message(v-if = '!validationState.amount.max') {{ $t('maxError', { value: validationState.amountMax.$model }) }}
                .error-message(v-if = '!validationState.amount.required') {{ $t('common:required') }}
              .col-md
                .error-message(v-if = '!validationState.amountMin.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.amountMin.min') {{ $t('minError', { value: validationState.amountSliderStep.$model }) }}
                .error-message(v-if = '!validationState.amountMin.max') {{ $t('maxError', { value: validationState.amountMax.$model }) }}
              .col-md
                .error-message(v-if = '!validationState.amountMax.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.amountMax.min') {{ $t('minError', { value: validationState.amountMin.$model }) }}
              .col-md
                .error-message(v-if = '!validationState.amountSliderStep.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.amountSliderStep.min') {{ $t('minError', { value: validationState.amountSliderStep.$params.min.min }) }}
          .row
            .col
              fi-select(
                v-model.number = 'validationState.currencyId.$model'
                :options       = 'optionsFromClassifier("currencies")'
                :class         = '{ "is-invalid": validationState.currencyId.$error }'
                required
                sm
              )
            .input-group.input-group-sm.col-md
              .input-group-prepend
                span.input-group-text {{ $t('common:default') }}
              input.form-control.form-control-sm(
                v-model.number = 'productData.amount'
                :class         = '{ "is-invalid": validationState.amount.$error }'
              )
            .input-group.input-group-sm.col-md
              .input-group-prepend
                span.input-group-text {{ $t('common:min') }}
              input.form-control.form-control-sm(
                v-model.number = 'productData.amountMin'
                :class         = '{ "is-invalid": validationState.amountMin.$error }'
              )
            .input-group.input-group-sm.col-md
              .input-group-prepend
                span.input-group-text {{ $t('common:max') }}
              input.form-control.form-control-sm(
                v-model.number = 'productData.amountMax'
                :class         = '{ "is-invalid": validationState.amountMax.$error }'
              )
            .input-group.input-group-sm.col-md
              .input-group-prepend
                span.input-group-text {{ $t('step') }}
              input.form-control.form-control-sm(
                v-model.number = 'productData.amountSliderStep'
                :class         = '{ "is-invalid": validationState.amountSliderStep.$error }'
              )
        td
          fi-switch(v-model = 'productData.currencyIdOverride')
      tr
        td {{ $t('loanLimitRate') }}
        td: fi-form-field.mb-0
          percent-input.form-control.form-control-sm(v-model.number = 'productData.loanLimitRate')
        td
      tr
        td {{ $t('paymentDay') }}
        td: fi-form-field.mb-0(:state = '!validationState.invoicingDay.$error')
          template(#error)
            .error-message(v-if = '!validationState.invoicingDay.required') {{ $t('common:required') }}
            .error-message(v-if = '!validationState.invoicingDay.maxValue') {{ $t('maxError', { value: validationState.invoicingDay.$params.maxValue.max }) }}
          input.form-control.form-control-sm(
            v-model.number = 'validationState.invoicingDay.$model'
            :class         = '{ "is-invalid": validationState.invoicingDay.$error }'
          )
        td
      tr
        td {{ $t('paymentTerms') }}
        td: fi-form-field.mb-0(:state = '!validationState.invoicePaymentTerms.$error')
          template(#error)
            .error-message(v-if = '!validationState.invoicePaymentTerms.required') {{ $t('common:required') }}
          input.form-control.form-control-sm(
            v-model.number = 'validationState.invoicePaymentTerms.$model'
            :class         = '{ "is-invalid": validationState.invoicePaymentTerms.$error }'
          )
        td
      tr
        td {{ $t('interestRate') }}
        td: fi-form-field.mb-0(:state = '!validationState.interestRateGroup.$error')
          template(#error)
            .row
              .col-md
                .error-message(v-if = '!validationState.dayCountConventionId.required') {{ $t('common:required') }}
              .col-md-3
                .error-message(v-if = '!validationState.interestRate.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.interestRate.min') {{ formatRangeError('minError', validationState.interestRateMin.$model, true) }}
                .error-message(v-if = '!validationState.interestRate.max') {{ formatRangeError('maxError', validationState.interestRateMax.$model, true) }}
              .col-md-3
                .error-message(v-if = '!validationState.interestRateMin.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.interestRateMin.max') {{ formatRangeError('maxError', validationState.interestRateMax.$model, true) }}
              .col-md-3
                .error-message(v-if = '!validationState.interestRateMax.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.interestRateMax.min') {{ formatRangeError('minError', validationState.interestRateMin.$model, true) }}
          .row
            .col-md
              fi-select(
                v-model.number = 'validationState.dayCountConventionId.$model'
                :options       = 'optionsFromClassifier("dayCountConventions")'
                :class         = '{ "is-invalid": validationState.dayCountConventionId.$error }'
                required
                sm
              )
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:default') }}
              percent-input.form-control.form-control-sm(
                v-model.number = 'validationState.interestRate.$model'
                :class         = '{ "is-invalid": validationState.interestRate.$error }'
              )
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:min') }}
              percent-input.form-control.form-control-sm(
                v-model.number = 'validationState.interestRateMin.$model'
                :class         = '{ "is-invalid": validationState.interestRateMin.$error }'
              )
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:max') }}
              percent-input.form-control.form-control-sm(
                v-model.number = 'validationState.interestRateMax.$model'
                :class         = '{ "is-invalid": validationState.interestRateMax.$error }'
              )
        td
          fi-switch(v-model = 'productData.dayCountConventionIdOverride')
      tr
        td {{ $t('penaltyRate') }}
        td: fi-form-field.mb-0(:state = '!validationState.penaltyDayCountConventionId.$error')
          template(#error)
            .error-message(v-if = '!validationState.penaltyDayCountConventionId.required') {{ $t('common:required') }}
          .row
            .col-md
              fi-select(
                v-model.number = 'validationState.penaltyDayCountConventionId.$model'
                :options       = 'optionsFromClassifier("dayCountConventions")'
                :class         = '{ "is-invalid": validationState.penaltyDayCountConventionId.$error }'
                required
                sm
              )
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:default') }}
              percent-input.form-control.form-control-sm(v-model.number = 'productData.penaltyInterestRate')
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:min') }}
              percent-input.form-control.form-control-sm(v-model.number = 'productData.penaltyInterestRateMin')
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:max') }}
              percent-input.form-control.form-control-sm(v-model.number = 'productData.penaltyInterestRateMax')
        td
          fi-switch(v-model = 'productData.penaltyDayCountConventionIdOverride')
      tr
        td {{ $t('penaltyRateCalculation') }}
        td
          .custom-control.custom-checkbox
            input.custom-control-input(
              v-model = 'productData.includeAllInvoicesToPenalty'
              id      = 'penaltyRateCalculation'
              type    = 'checkbox'
              :value  = 'true'
            )
            label.custom-control-label(for = 'penaltyRateCalculation') {{ $t('penaltyRateCalculationPh') }}
        td
      tr
        td {{ $t('interestPaymentPeriod') }}
        td: fi-form-field.mb-0(
          :state = '!validationState.interestPaymentPeriodId.$error'
          :disabled = 'isDayPeriod'
        )
          template(#error)
            .error-message(v-if = '!validationState.interestPaymentPeriodId.required') {{ $t('common:required') }}
            .error-message(v-if = '!validationState.interestPaymentPeriodId.tenor') {{ $t('tenorError') }}
          fi-select(
            v-model.number = 'validationState.interestPaymentPeriodId.$model'
            :options       = 'optionsFromClassifier("paymentPeriodTypes")'
            :class         = '{ "is-invalid": validationState.interestPaymentPeriodId.$error }'
            required
            sm
          )
      tr
        td {{ $t('principalPaymentPeriod') }}
        td: fi-form-field.mb-0(
          :state    = '!validationState.principalPaymentPeriodId.$error'
          :disabled = 'isDayPeriod'
        )
          template(#error)
            .error-message(v-if = '!validationState.principalPaymentPeriodId.required') {{ $t('common:required') }}
            .error-message(v-if = '!validationState.principalPaymentPeriodId.tenor') {{ $t('tenorError') }}
            .error-message(v-if = '!validationState.upfrontAmountCalculationType.required') {{ $t('common:required') }}
          .row
            .col
              fi-select(
                v-model.number = 'validationState.principalPaymentPeriodId.$model'
                :options       = 'principalPaymentPeriods'
                :class         = '{ "is-invalid": validationState.principalPaymentPeriodId.$error }'
                required
                sm
              )
            .col
              fi-select(
                v-model.number = 'validationState.principalPaybackAmountCalculationType.code.$model'
                :options       = 'calculationTypeOptions'
                :class         = '{ "is-invalid": validationState.principalPaybackAmountCalculationType.$error }'
                required
                sm
              )
            .col
              percent-input.form-control.form-control-sm(
                v-if           = 'percentagePrincipalPaybackAmount'
                v-model.number = 'validationState.principalPaybackAmount.$model'
                :class         = '{ "is-invalid": validationState.principalPaybackAmount.$error }'
              )
              input.form-control.form-control-sm(
                v-else
                v-model.number = 'validationState.principalPaybackAmount.$model'
                :class         = '{ "is-invalid": validationState.principalPaybackAmount.$error }'
              )
      template(v-if = 'isFinland')
        tr
          td {{ $t('penaltyRateChangeDays') }}
          td: fi-form-field.mb-0(:state = '!validationState.automaticPenaltyRateChangeDays.$error')
            template(#error)
              .error-message(v-if = '!validationState.automaticPenaltyRateChangeDays.required') {{ $t('common:required') }}
            input.form-control.form-control-sm(
              v-model.number = 'validationState.automaticPenaltyRateChangeDays.$model'
              :class         = '{ "is-invalid": validationState.automaticPenaltyRateChangeDays.$error }'
            )
          td
        tr
          td {{ $t('penaltyRateChangeRate') }}
          td: fi-form-field.mb-0(:state = '!validationState.automaticPenaltyRateChangeRate.$error')
            template(#error)
              .error-message(v-if = '!validationState.automaticPenaltyRateChangeRate.required') {{ $t('common:required') }}
            percent-input.form-control.form-control-sm(
              v-model.number = 'validationState.automaticPenaltyRateChangeRate.$model'
              :class         = '{ "is-invalid": validationState.automaticPenaltyRateChangeRate.$error }'
            )
          td
      tr
        td.fee-row(:colspan = '3')
          .fee-title {{ $t('fees') }}
          table.table.fee-table
            thead: tr
              th {{ $t('feeTableTitle') }}
              th {{ $t('feeCalculationMethod') }}
              th {{ $t('common:default') }}
              th {{ $t('common:min') }}
              th {{ $t('common:max') }}
              th {{ $t('changesAllowed') }}
            tbody
              product-fee-item.animated.fadeIn(
                v-for         = '(fee, index) in validationState.fees.$each.$iter'
                :key          = 'index'
                :fee          = 'fee'
                :removable    = 'validationState.fees.$model.length > validationState.fees.$params.minLength.min'
                :product-data = 'productData'
                :type-options = 'feeTypeOptions'
                :required     = 'feeRequired(fee)'
                :fee-configs  = 'feeConfigs'
                @remove-fee   = 'removeFee(index)'
              )
      tr(v-if = 'feeTypeOptions.length')
        td.border-top-0.text-center(colspan='3')
          button.btn.btn-link(@click.prevent = 'addFee') {{ $t('addFee') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMultiselect from '@/components/FiMultiselect'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import PercentInput from '@/components/PercentInput'
import { formatRangeError } from '@/mixins'
import ProductFeeItem from '@/views/admin/products/ProductFeeItem'
import differenceWith from 'lodash/differenceWith'
import { mapGetters, mapState } from 'vuex'
import { convertFee, fee, feePercentage } from '@/helpers/feeUtils'
import { GUARANTEE_FEE, AUTO_EXTENSION, GUARANTEE_FEE_BY_GOV } from './variables'

export default {
  name: 'product-attributes-overdraft',

  components: {
    ProductFeeItem,
    FiMultiselect,
    FiSwitch,
    PercentInput,
    FiFormField,
    FiSelect
  },

  mixins: [formatRangeError],

  props: {
    productData: {
      type: Object,
      required: true
    },
    validationState: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('products', ['feeConfigs']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByName', 'classifierList']),
    ...mapState('settings', ['settings']),
    principalPaymentPeriods () {
      return this.optionsFromClassifier('paymentPeriodTypes').filter(period => period.value >= this.productData.interestPaymentPeriodId)
    },
    periodUnitOptions () {
      return Object.values(this.classifierList('periodUnitType'))
        .filter(({ productGroups }) => productGroups.includes('LOAN'))
        .map(({ id, human }) => ({ text: human, value: id }))
    },
    isInterestTenor () {
      return /TENOR/i.test(this.classifierById('paymentPeriodTypes', this.productData.interestPaymentPeriodId).code)
    },
    isPrincipalTenor () {
      return /TENOR/i.test(this.classifierById('paymentPeriodTypes', this.productData.principalPaymentPeriodId).code)
    },
    isDayPeriod () {
      return /DAY/i.test(this.classifierById('periodUnitType', this.productData.periodUnitTypeId).code)
    },
    isFinland () {
      return /fi/i.test(this.settings.country)
    },
    feeTypeOptions () {
      return differenceWith(
        this.optionsFromClassifier('feeTypeEntity', true),
        this.validationState.fees.$model,
        (option, fee) => option.value === fee.feeType.code)
        .filter((el) => el.text !== AUTO_EXTENSION && el.text !== GUARANTEE_FEE && el.text !== GUARANTEE_FEE_BY_GOV)
    },
    calculationTypeOptions () {
      const feeCalculationTypePossible = this.feeConfigs.PRODUCT_ATTRIBUTE
      return this.optionsFromClassifier('feeCalculationType', true)
        .filter(({ value }) => feeCalculationTypePossible?.includes(value) ?? value)
    },
    percentagePrincipalPaybackAmount () {
      return feePercentage(this.productData.principalPaybackAmountCalculationType.code)
    }
  },

  watch: {
    'productData.periodUnitTypeId' () {
      if (this.isDayPeriod) {
        this.productData.interestPaymentPeriodId = this.classifierByName('paymentPeriodTypes', 'TENOR')?.id
        this.productData.principalPaymentPeriodId = this.classifierByName('paymentPeriodTypes', 'TENOR')?.id
        this.productData.interestPaymentPeriodIdOverride = false
        this.productData.principalPaymentPeriodIdOverride = false
      }
    },
    'productData.interestPaymentPeriodId' (val) {
      if (this.productData.principalPaymentPeriodId < val) this.productData.principalPaymentPeriodId = val
      if (this.isInterestTenor && this.isDayPeriod) {
        this.productData.interestPaymentPeriodIdOverride = false
      }
    },
    'productData.principalPaymentPeriodId' () {
      if (this.isPrincipalTenor && this.isDayPeriod) {
        this.productData.principalPaymentPeriodIdOverride = false
      }
    },
    percentagePrincipalPaybackAmount (value, oldValue) {
      if (value === oldValue || typeof oldValue === 'undefined') {
        return
      }

      this.validationState.principalPaybackAmount.$model = convertFee({
        feeValue: this.validationState.principalPaybackAmount.$model,
        isPercentage: value,
        amountValue: this.productData.amount ?? 0
      })
    }
  },

  created () {
    this.productData.scheduleTypeId = this.classifierByName('scheduleTypes', 'OVERDRAFT')?.id
  },

  methods: {
    addFee () {
      this.productData.fees = [...this.productData.fees, fee()]
    },
    removeFee (id) {
      this.productData.fees.splice(id, 1)
    },
    feeRequired (fee) {
      return [...Object.keys(this.feeConfigs), 'AUTO_EXTENSION'].some(feeType => new RegExp(feeType, 'i').test(fee.feeType.code.$model))
    }
  }
}
</script>


<style lang="scss" scoped>
.product-attributes {
  .fee-row {
    padding: 0.75rem 0;

    .fee-title {
      padding: 0.75rem;
    }

    .fee-table {
      & /deep/ thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
    }
  }
}
</style>


<i18n>
en:
  period:                          "Agreement length"
  extensionPeriod:                 "Automatic extension"
  amount:                          "Amount"
  step:                            "Step"
  currency:                        "Currency"
  interestRate:                    "Loan interest"
  dayCountConvention:              "Loan day count convention"
  penaltyRate:                     "Penalty interest"
  penaltyDayCountConvention:       "Penalty day count convention"
  scheduleType:                    "Schedule type"
  firstPrincipalPayment:           "First principal payment (months)"
  firstPrincipalFieldPh:           "Enter first principal payment"
  principalPaymentPeriod:          "Principal payment period"
  firstInterestPayment:            "First interest payment (months)"
  firstInterestPaymentPh:          "Enter first interest payment"
  interestPaymentPeriod:           "Interest payment period"
  loanLimitRate:                   "Loan limit rate"
  monthlyServiceFee:               "Monthly service fee"
  paymentDay:                      "Invoice day of the month"
  paymentTerms:                    "Invoice payment terms (days)"
  fees:                            "Product fees"
  feeMethod:                       "Fee calculation method"
  feeName:                         "Fee type name"
  addFee:                          "+ Add new fee"
  minError:                        "Value should be greater or equal to {{ value }}"
  maxError:                        "Value should be less or equal to {{ value }}"
  tenorError:                      "Field should be TENOR"
  maxExtensions:                   "Max nr of extensions"
  penaltyRateChangeDays:           "Automatic penalty rate change (days)"
  penaltyRateChangeRate:           "Automatic penalty rate change (%)"
  residualAmount:                  "Residual amount"
  residualAmountCalculationType:   "Residual amount method"
  upfrontAmount:                   "Upfront amount"
  upfrontAmountCalculationType:    "Upfront amount method"
  vat:                             "VAT"
  feeTableTitle:                   "Fee name"
  feeCalculationMethod:            "Calculation method"
  penaltyRateCalculation:          "Penalty interest calculation"
  penaltyRateCalculationPh:        "Include all invoices in debt"
et:
  period:                          "Agreement length"
  extensionPeriod:                 "Automatic extension"
  amount:                          "Amount"
  step:                            "Step"
  currency:                        "Currency"
  interestRate:                    "Loan interest"
  dayCountConvention:              "Loan day count convention"
  penaltyRate:                     "Penalty interest"
  penaltyDayCountConvention:       "Penalty day count convention"
  scheduleType:                    "Schedule type"
  firstPrincipalPayment:           "First principal payment (months)"
  firstPrincipalFieldPh:           "Enter first principal payment"
  principalPaymentPeriod:          "Principal payment period"
  firstInterestPayment:            "First interest payment (months)"
  firstInterestPaymentPh:          "Enter first interest payment"
  interestPaymentPeriod:           "Interest payment period"
  loanLimitRate:                   "Loan limit rate"
  monthlyServiceFee:               "Monthly service fee"
  paymentDay:                      "Invoice day of the month"
  paymentTerms:                    "Invoice payment terms (days)"
  fees:                            "Product fees"
  feeMethod:                       "Fee calculation method"
  feeName:                         "Fee type name"
  addFee:                          "+ Add new fee"
  minError:                        "Value should be greater or equal to {{ value }}"
  maxError:                        "Value should be less or equal to {{ value }}"
  tenorError:                      "Field should be TENOR"
  maxExtensions:                   "Max nr of extensions"
  penaltyRateChangeDays:           "Automatic penalty rate change (days)"
  penaltyRateChangeRate:           "Automatic penalty rate change (%)"
  residualAmount:                  "Residual amount"
  residualAmountCalculationType:   "Residual amount method"
  upfrontAmount:                   "Upfront amount"
  upfrontAmountCalculationType:    "Upfront amount method"
  vat:                             "VAT"
  feeTableTitle:                   "Fee name"
  feeCalculationMethod:            "Calculation method"
  penaltyRateCalculation:          "Penalty interest calculation"
  penaltyRateCalculationPh:        "Include all invoices in debt"
ru:
  period:                          "Agreement length"
  extensionPeriod:                 "Automatic extension"
  amount:                          "Amount"
  step:                            "Step"
  currency:                        "Currency"
  interestRate:                    "Loan interest"
  dayCountConvention:              "Loan day count convention"
  penaltyRate:                     "Penalty interest"
  penaltyDayCountConvention:       "Penalty day count convention"
  scheduleType:                    "Schedule type"
  firstPrincipalPayment:           "First principal payment (months)"
  firstPrincipalFieldPh:           "Enter first principal payment"
  principalPaymentPeriod:          "Principal payment period"
  firstInterestPayment:            "First interest payment (months)"
  firstInterestPaymentPh:          "Enter first interest payment"
  interestPaymentPeriod:           "Interest payment period"
  loanLimitRate:                   "Loan limit rate"
  monthlyServiceFee:               "Monthly service fee"
  paymentDay:                      "Invoice day of the month"
  paymentTerms:                    "Invoice payment terms (days)"
  fees:                            "Product fees"
  feeMethod:                       "Fee calculation method"
  feeName:                         "Fee type name"
  addFee:                          "+ Add new fee"
  minError:                        "Value should be greater or equal to {{ value }}"
  maxError:                        "Value should be less or equal to {{ value }}"
  tenorError:                      "Field should be TENOR"
  maxExtensions:                   "Max nr of extensions"
  penaltyRateChangeDays:           "Automatic penalty rate change (days)"
  penaltyRateChangeRate:           "Automatic penalty rate change (%)"
  residualAmount:                  "Residual amount"
  residualAmountCalculationType:   "Residual amount method"
  upfrontAmount:                   "Upfront amount"
  upfrontAmountCalculationType:    "Upfront amount method"
  vat:                             "VAT"
  feeTableTitle:                   "Fee name"
  feeCalculationMethod:            "Calculation method"
  penaltyRateCalculation:          "Penalty interest calculation"
  penaltyRateCalculationPh:        "Include all invoices in debt"
</i18n>
