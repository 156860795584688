<template lang = 'pug'>
.product-templates.table-responsive.mb-0
  table.table
    thead: tr
      th {{ $t('fieldName') }}
      th {{ $t('fieldValue') }}
      th {{ $t('changesAllowed') }}
    tbody
      tr(v-if = 'productData.hasOwnProperty("agreementTemplateId")')
        td {{ $t('agreementTemplate') }}
        td: fi-form-field.mb-0
          fi-select(
            v-model.number = 'productData.agreementTemplateId'
            :options       = 'optionsFromTemplates(["AGREEMENT_PDF_TEMPLATE"])'
            sm
          )
        td: fi-switch(v-model = 'productData.agreementTemplateIdOverride')
      tr(v-if = 'productData.hasOwnProperty("scheduleTemplateId")')
        td {{ $t('scheduleTemplate') }}
        td: fi-form-field.mb-0
          fi-select(
            v-model.number = 'productData.scheduleTemplateId'
            :options       = 'optionsFromTemplates(["SCHEDULE_PDF_TEMPLATE"])'
            sm
          )
        td: fi-switch(v-model = 'productData.scheduleTemplateIdOverride')
      tr(v-if = 'productData.hasOwnProperty("offerTemplateId")')
        td {{ $t('offerTemplate') }}
        td: fi-form-field.mb-0
          fi-select(
            v-model.number = 'productData.offerTemplateId'
            :options       = 'optionsFromTemplates(["OFFER_EMAIL_TEMPLATE"])'
            sm
          )
        td: fi-switch(v-model = 'productData.offerTemplateIdOverride')
      tr(v-if = 'productData.hasOwnProperty("informationSheetTemplateId")')
        td {{ $t('infoSheetTemplate') }}
        td: fi-form-field.mb-0
          fi-select(
            v-model.number = 'productData.informationSheetTemplateId'
            :options       = 'optionsFromTemplates(["INFO_SHEET_PDF_TEMPLATE"])'
            sm
            )
        td: fi-switch(v-model = 'productData.informationSheetTemplateIdOverride')
      tr(v-if = 'productData.hasOwnProperty("acceptTermsTemplateId")')
        td {{ $t('acceptTermsTemplate') }}
        td: fi-form-field.mb-0
          fi-select(
            v-model.number = 'productData.acceptTermsTemplateId'
            :options       = 'optionsFromTemplates(["APPLICATION_ACCEPT_TERMS_TEMPLATE"])'
            sm
          )
        td
</template>

<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { mapGetters } from 'vuex'

export default {
  name: 'product-templates',

  components: {
    FiSwitch,
    FiFormField,
    FiSelect
  },

  props: {
    productData: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapGetters('templates', ['optionsFromTemplates'])
  }
}
</script>
