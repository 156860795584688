<template lang='pug'>
.product-general
  fi-form-field(
    :label     = '$t("status")'
    label-cols = '3'
  )
    .switch-status-wrap
      fi-switch(
        v-model   = 'productData.active'
        :on-text  = '$t("common:enabled")'
        :off-text = '$t("common:disabled")'
      )
  fi-form-field(
    :label     = '$t("statusPortal")'
    label-cols = '3'
  )
    .switch-status-wrap
      fi-switch(
        v-model   = 'productData.availableInPortal'
        :on-text  = '$t("common:enabled")'
        :off-text = '$t("common:disabled")'
      )

  fi-form-field(
    v-if   = '$route.name !== "NewProduct"'
    :label     = '$t("statusContracts")'
    label-cols = '3'
  )
    .switch-status-wrap
      fi-switch(
        v-model   = 'productData.showContractsInPortal'
        :on-text  = '$t("common:enabled")'
        :off-text = '$t("common:disabled")'
      )

  fi-form-field(
    :label     = '$t("code")'
    label-cols = '3'
    :state     = '!validationState.code.$error'
    :disabled  = '!isNewProduct'
  )
    template(#error)
      .error-message(v-if = '!validationState.code.required') {{ $t('common:required') }}
      .error-message(v-if = '!validationState.code.maxLength') {{ $t('lengthError', { value: validationState.code.$params.maxLength.max }) }}

    input.form-control.form-control-sm(
      v-model.trim = 'productData.code'
      :placeholder = '$t("codeFieldPh")'
      :class       = '{ "is-invalid": validationState.code.$error }'
    )

  fi-form-field(
    :label     = '$t("name")'
    label-cols = '3'
    :state     = '!validationState.name.$error'
  )
    template(#error)
      .error-message(v-if = '!validationState.name.required') {{ $t('common:required') }}

    input.form-control.form-control-sm(
      v-model.trim = 'productData.name'
      :placeholder = '$t("nameFieldPh")'
      :class       = '{ "is-invalid": validationState.name.$error }'
    )

  fi-form-field(
    :label     = '$t("group")'
    label-cols = '3'
    :state     = '!validationState.group.$error'
    :disabled  = '!isNewProduct'
  )
    template(#error)
      .error-message(v-if = '!validationState.group.required') {{ $t('common:required') }}

    fi-select(
      v-model.number = 'productData.group'
      :options       = 'optionsFromClassifier("productGroups", true)'
      :class         = '{ "is-invalid": validationState.group.$error }'
      :disabled      = '!!productData.group'
      required
      sm
    )

  fi-form-field(
    :label     = '$t("type")'
    label-cols = '3'
    :state     = '!validationState.productType.$error'
    :disabled  = '!isNewProduct && !validationState.productType.$dirty && !validationState.productType.$invalid'
  )
    template(#error)
      .error-message(v-if = '!validationState.productType.required') {{ $t('common:required') }}

    fi-select(
      v-model.number = 'validationState.productType.$model'
      :options       = 'productTypesOptions'
      :class         = '{ "is-invalid": validationState.productType.$error }'
      required
      sm
    )

  fi-form-field(
    :label     = '$t("creditIssuer")'
    label-cols = '3'
    :state     = '!validationState.creditIssuerId.$error'
  )
    template(#error)
      .error-message(v-if = '!validationState.creditIssuerId.required') {{ $t('common:required') }}

    fi-select(
      v-model.number = 'validationState.creditIssuerId.$model'
      :options       = 'optionsFromCreditIssuers'
      :class         = '{ "is-invalid": validationState.creditIssuerId.$error }'
      required
      sm
    )

  template(v-if = 'productData.hasOwnProperty("sectorId")')
    fi-form-field.animated.fadeIn(
      :label     = '$t("sector")'
      label-cols = '3'
      :state     = '!validationState.sectorId.$error'
    )
      template(#error)
        .error-message(v-if = '!validationState.sectorId.required') {{ $t('common:required') }}

      fi-select.animated.fadeIn(
        v-model.number = 'validationState.sectorId.$model'
        :class         = '{ "is-invalid": validationState.sectorId.$error }'
        :options       = 'optionsFromClassifier("sectors")'
        required
        sm
      )

  fi-form-field(
    :label     = '$t("segment")'
    label-cols = '3'
    :state     = '!validationState.segments.$error'
  )
    template(#error)
      .error-message(v-if = '!validationState.segments.required') {{ $t('common:required') }}

    fi-multiselect(
      v-model          = 'selectedSegments'
      :options         = 'optionsFromClassifier("segments")'
      :class           = '{ "is-invalid": validationState.segments.$error }'
      track-by         = 'value'
      label            = 'text'
      multiple
      hide-selected
      sm
    )

  fi-form-field(
    :label     = '$t("creditIssuerAccount")'
    label-cols = '3'
    :state     = '!validationState.externalBankAccounts.$error'
  )
    template(#error)
      .error-message(v-if = '!validationState.externalBankAccounts.required') {{ $t('common:required') }}
    fi-multiselect(
      v-model     = 'validationState.externalBankAccounts.$model'
      :options    = 'issuerAccounts'
      :allowEmpty = 'false'
      :class      = '{ "is-invalid": validationState.externalBankAccounts.$error }'
      track-by  = 'accountExternalNumber'
      label     = 'accountExternalNumber'
      taggable
      multiple
      sm
      @tag        = 'addAccount'
    )

  template(v-if = '!(isDeposit || isFactoring)')
    fi-form-field.animated.fadeIn(
      :label     = '$t("checkApr")'
      label-cols = '3'
    )
      .switch-status-wrap
        fi-switch(v-model = 'productData.checkApr')

    fi-form-field.animated.fadeIn(
      :label     = '$t("warrantyInfo")'
      label-cols = '3'
    )
      .switch-status-wrap
        fi-switch(v-model = 'productData.warrantyInfo')

    fi-form-field.animated.fadeIn(
      :label     = '$t("hirePurchaseInfo")'
      label-cols = '3'
    )
      .switch-status-wrap
        fi-switch(v-model = 'productData.hirePurchaseInfo')

    fi-form-field.animated.fadeIn(
      v-if       = '(productData.warrantyInfo || productData.hirePurchaseInfo)'
      :label     = '$t("assetTypes")'
      label-cols = '3'
    )
      fi-multiselect(
        v-model   = 'selectedAssetTypes'
        :options  = 'optionsFromClassifier("assetTypes", true)'
        :disabled = '!(productData.warrantyInfo || productData.hirePurchaseInfo)'
        track-by  = 'value'
        label     = 'text'
        multiple
        hide-selected
      )
  fi-form-field(
    v-if = 'productData.hasOwnProperty("dayChangeSettlementMethod")'
    :label     = '$t("transactionsMethod")'
    label-cols = '3'
  )
    fi-select.mb-3(
      v-model.number = 'validationState.dayChangeSettlementMethod.$model'
      :class         = '{ "is-invalid": validationState.dayChangeSettlementMethod.$error }'
      :options       = 'isOverdraft ? optionsFromClassifier("transactionMethods").splice(-1, 1) : optionsFromClassifier("transactionMethods")'
      required
      sm
    )
  fi-form-field(
    v-if = 'productData.hasOwnProperty("dayChangeSettlementOrder")'
    :label     = '$t("transactionsOrder")'
    label-cols = '3'
  )
    fi-select.mb-3(
      v-model.number = 'validationState.dayChangeSettlementOrder.$model'
      :class         = '{ "is-invalid": validationState.dayChangeSettlementOrder.$error }'
      :options       = 'optionsFromSettlementOrders'
      required
      sm
    )
    draggable.list-group.list-group-horizontal(v-model = 'transactions')
      button.list-group-item-action.mx-1.rounded.list-group-item.justify-content-between.d-flex.align-items-center(
        v-for = '(transaction, idx) in transactions'
        :key  = 'idx'
        @click.prevent
      ) {{ transaction }}
        span.badge.badge-secondary.badge-pill.ml-2 {{ idx+1 }}
    template(error)
      .error-message.red(v-if = 'errorPenalty && dayChangeSettlementOrder') {{ $t('errorPenalty') }}
</template>


<script>
import { mapGetters } from 'vuex'
import FiSwitch from '@/components/FiSwitch'
import PercentInput from '@/components/PercentInput'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiMultiselect from '@/components/FiMultiselect'
import draggable from 'vuedraggable'

export default {
  name: 'product-general',

  components: {
    FiSwitch,
    PercentInput,
    FiFormField,
    FiSelect,
    FiMultiselect,
    draggable
  },

  props: {
    productData: {
      type: Object,
      required: true
    },
    validationState: {
      type: Object,
      required: true
    },
    isNewProduct: {
      type: Boolean,
      required: true
    },
    isDeposit: {
      type: Boolean,
      required: true
    },
    isFactoring: {
      type: Boolean,
      required: true
    },
    isOverdraft: {
      type: Boolean,
      required: true
    },
    issuerAccounts: {
      type: Array,
      required: true
    },
    errorPenalty: {
      type: Boolean,
      required: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'optionsFromCreditIssuers', 'classifierById', 'classifierByName', 'classifierByCode', 'productTypesByGroup']),
    ...mapGetters('products', ['optionsFromSettlementOrders']),
    productTypesOptions () {
      return this.productTypesByGroup(this.productData.group).map(({ human, code }) => ({ text: human, value: code }))
    },
    dayChangeSettlementOrder () {
      const dayChangeSettlementOrder = this.validationState.dayChangeSettlementOrder.$model.split(',').map(item => item.trim())
      return dayChangeSettlementOrder.indexOf('PENALTY') !== dayChangeSettlementOrder.length - 1 && dayChangeSettlementOrder.indexOf('PENALTY') !== 0
    },
    creditIssuerAccountOptions () {
      return this.optionsFromCreditIssuers.find(({ value }) => value === this.productData.creditIssuerId)?.externalBankAccounts
    },
    selectedAssetTypes: {
      get () {
        return this.productData.assetTypes?.map(assetType => ({ value: assetType, text: this.classifierByCode('assetTypes', assetType).human }))
      },
      set (assetTypes) {
        if (assetTypes.some(({ value }) => !value)) return
        this.productData.assetTypes = assetTypes.map(({ value }) => (value))
      }
    },
    selectedSegments: {
      get () {
        return this.productData?.segments?.map(({ id }) => ({ value: id, text: this.classifierById('segments', id).human }))
      },
      set (segments) {
        if (segments.some(({ value }) => !value)) return
        this.productData.segments = segments.map(({ value }) => ({ id: value }))
      }
    },
    transactions: {
      get () {
        return this.productData.dayChangeSettlementOrder?.split(',').map(item => item.trim())
      },
      set (list) {
        this.productData.dayChangeSettlementOrder = list.join(',')
      }
    }
  },

  watch: {
    'productData.active' (value) {
      !value && this.$emit('switch-active', { status: value })
    }
  },

  methods: {
    addAccount (account) {
      this.creditIssuerBankAccountModel = [...this.creditIssuerBankAccountModel, { text: account, value: account }]
    }
  }
}
</script>

<style lang='scss' scoped>
  .red {
    color: red;
    font-size: 80%;
  }
</style>
