<template lang='pug'>
.product-financial.table-responsive.mb-0
  table.table
    thead: tr
      th
      th {{ $t('fieldKey') }}
      th {{ $t('fieldValue') }}
      th.text-right
        button.ml-auto.btn-link.btn(@click.prevent = 'showAddModal = true') {{ $t('addNew') }}
    tbody
      tr(v-for = '(field, index) in sortFinancialData')
        td {{ field.id }}
        td {{ field.classifierKey }}
        td {{ field.classifierValue }}
        td.text-right
          button.btn.btn-danger(@click.prevent = 'updateFinancialData({ index })')
            i.far.fa-trash-alt.fa-lg
  product-financial-field-modal(
    v-model         = 'showAddModal'
    :financial-fields = 'editableClassifiers'
    @save           = 'addFinancialField'
  )
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { mapActions, mapGetters, mapState } from 'vuex'
import ProductFinancialFieldModal from '@/views/admin/products/ProductFinancialFieldModal'

export default {
  name: 'product-financial',

  components: { ProductFinancialFieldModal, FiSwitch, FiSelect, FiMoneyInput, FiFormField },

  props: {
    financialData: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    showAddModal: false
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapState('classifiers', ['editableClassifiers']),
    sortFinancialData () {
      const list = JSON.parse(JSON.stringify(this.financialData))
      const sortList = list.sort(function (a, b) {
        const nameA = a.classifierKey
        const nameB = b.classifierKey
        if (nameA > nameB) return -1
        if (nameA < nameB) return 1
        return 0
      })
      return sortList
    }
  },

  created () {
    this.loadEditableClassifiers()
  },

  methods: {
    ...mapActions('classifiers', ['loadEditableClassifiers']),
    addFinancialField ({ field, done }) {
      this.updateFinancialData({ field })
      done()
    },
    updateFinancialData ({ field, index = -1 }) {
      this.$emit('update', { field, index })
    }
  }
}
</script>


<i18n>
en:
  fieldKey:   "Key"
  fieldValue: "Value"
  addNew:    "+ Add new"
et:
  fieldKey:   "Key"
  fieldValue: "Value"
  addNew:    "+ Add new"
ru:
  fieldKey:   "Key"
  fieldValue: "Value"
  addNew:    "+ Add new"
</i18n>
