<template lang='pug'>
tr.animated.fadeIn
  td
    fi-form-field.mb-0
      .row
        .col
          template(v-if = 'feeSelected.systemFee') {{ feeTypeSelected.text }}
          fi-multiselect(
            v-else
            v-model  = 'feeTypeSelected'
            :options = 'typeOptions'
            track-by = 'value'
            label    = 'text'
          )
          div(v-if = 'isFeeRegular && productData.interestPaymentPeriodId') ({{ classifierById('paymentPeriodTypes', productData.interestPaymentPeriodId).human }})
        .col-auto(v-if = 'isFeeRegular && productData.interestPaymentPeriodId')
          span(:id = '`toggle_${_uid}`').fa-stack.fa-sm.text-info
            i.fas.fa-exclamation.fa-fw.fa-stack-1x
            i.far.fa-circle.fa-fw.fa-stack-2x
          b-popover(
            :target   = '`toggle_${_uid}`'
            triggers  = 'hover'
            placement = 'bottom'
          ) {{ $t('regularNotice') }}

  td
    fi-form-field.mb-0
      fi-select(
        v-model   = 'fee.feeCalculationType.code.$model'
        :options  = 'feeCalculationTypeOptions'
        required
        sm
      )
  td
    fi-form-field.mb-0(:state = '!fee.feeAmount.$error')
      .row
        .input-group.col-md.input-group-sm
          percent-input.form-control.form-control-sm(
            v-if           = 'percentageFee'
            v-model.number = 'fee.feeAmount.$model'
            :class         = '{ "is-invalid": fee.feeAmount.$error }'
          )
          input.form-control.form-control-sm(
            v-else
            v-model.number = 'fee.feeAmount.$model'
            :class         = '{ "is-invalid": fee.feeAmount.$error }'
          )
      template(#error)
        .row
          .col-md
            .error-message(v-if = '!fee.feeAmount.required') {{ $t('common:required') }}
  td
    fi-form-field.mb-0(:state = '!fee.feeMin.$error')
      .row
        .input-group.col-md.input-group-sm
          input.form-control.form-control-sm(
            v-model.number = 'fee.feeMin.$model'
            :class         = '{ "is-invalid": fee.feeMin.$error }'
          )
      template(#error)
        .row
          .col-md
            .error-message(v-if = '!fee.feeMin.required') {{ $t('common:required') }}
  td
    fi-form-field.mb-0(:state = '!fee.feeMax.$error')
      .row
        .input-group.col-md.input-group-sm
          input.form-control.form-control-sm(
            v-model.number = 'fee.feeMax.$model'
            :class         = '{ "is-invalid": fee.feeMax.$error }'
          )
      template(#error)
        .row
          .col-md
            .error-message(v-if = '!fee.feeMax.required') {{ $t('common:required') }}
  td
    .row
      .col
        fi-switch(v-model = 'fee.feeOverride.$model')
      .col(v-if = 'removable && !required')
        button.btn.btn-link.text-danger.py-0.btn-sm(@click.prevent = 'removeFee'): i.far.fa-trash-alt.fa-lg
  td(v-if = 'leasing')
    fi-switch(
      v-model   = 'fee.withVat.$model'
      :disabled = '!operational'
    )
</template>


<script>
import FiMultiselect from '@/components/FiMultiselect'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import FiFormField from '@/components/FiFormField'
import PercentInput from '@/components/PercentInput'
import { mapGetters } from 'vuex'
import { convertFee, feePercentage } from '@/helpers'

export default {
  name: 'product-fee-item',

  components: {
    FiSwitch,
    FiSelect,
    FiMultiselect,
    FiFormField,
    PercentInput
  },

  props: {
    fee: {
      type: Object,
      required: true
    },
    removable: {
      type: Boolean,
      default: false
    },
    productData: {
      type: Object,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    leasing: {
      type: Boolean,
      default: false
    },
    typeOptions: {
      type: Array,
      required: true
    },
    operational: {
      type: Boolean,
      default: false
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByCode']),
    feeCalculationTypeOptions () {
      const feeType = Object.keys(this.feeConfigs).includes(this.feeTypeSelected.value) ? this.feeTypeSelected.value : 'CUSTOM_FEE'
      const feeCalculationTypePossible = this.feeConfigs[feeType]
      return this.optionsFromClassifier(this.fee.feeCalculationType.classifier.$model, true)
        .filter(({ value }) => feeCalculationTypePossible?.includes(value) ?? value)
    },
    isFeeRegular () {
      return this.feeSelected?.feeGroup?.code === 'REGULAR'
    },
    feeSelected () {
      const { classifier, code } = this.fee.feeType.$model
      return this.classifierByCode(classifier, code)
    },
    feeTypeSelected: {
      get () {
        const { classifier, code } = this.fee.feeType.$model
        return this.optionsFromClassifier(classifier, true).find(({ value }) => value === code) ?? {}
      },
      set (fee) {
        this.fee.feeType.$model = {
          ...this.fee.feeType.$model,
          code: fee?.value
        }
      }
    },
    percentageFee () {
      return feePercentage(this.fee.feeCalculationType.code.$model)
    }
  },

  watch: {
    percentageFee (value, oldValue) {
      if (value === oldValue || typeof oldValue === 'undefined') {
        return
      }

      this.fee.feeAmount.$model = convertFee({
        feeValue: this.fee.feeAmount.$model,
        isPercentage: value,
        amountValue: this.productData.amount || 0
      })
    }
  },

  methods: {
    removeFee () {
      this.$emit('remove-fee', this.fee.id)
    }
  }
}
</script>


<i18n>
en:
  regularNotice: "Based on interest payment period"
et:
  regularNotice: "Based on interest payment period"
ru:
  regularNotice: "Based on interest payment period"
</i18n>
