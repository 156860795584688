<template lang="pug">
.product.card
  v-loading(loader = 'product:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
    template(v-if = 'productData')
      .card-header {{ header }}
      .card-body
        form.form-horizontal.animated.fadeIn(
          @submit.prevent = 'onSubmit'
          novalidate
        )
          button.btn.btn-primary.mb-3.mr-2(
            type      = 'submit'
            :disabled = 'saving'
          ) {{ $t('common:save') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
          button.btn.btn-secondary.mb-3(
            :disabled      = 'saving'
            @click.prevent = 'onCancel'
          ) {{ $t('common:cancel') }}
          b-tabs(pills lazy no-fade)
            b-tab(
              :title            = '$t("general")'
              :title-link-class = '["fi-tab", { invalid: $v.generalData.$error}]'
              lazy
            )
              product-general.animated.fadeIn(
                :product-data     = 'generalData'
                :validation-state = '$v.generalData'
                :is-new-product   = 'isNewProduct'
                :is-deposit       = 'isDeposit'
                :is-factoring     = 'isFactoring'
                :is-overdraft     = 'isOverdraft'
                :issuer-accounts  = 'creditIssuerAccountOptions'
                :error-penalty    = 'errorPenalty'
                @switch-active    = 'statusChanged'
              )
            b-tab(
              v-if              = 'generalData.group'
              :title            = '$t("productAttributes")'
              :title-link-class = '["fi-tab", { invalid: $v.attributesData.$error }]'
            )
              product-attributes-deposit.animated.fadeIn(
                v-if              = 'isDeposit'
                :product-data     = 'attributesData'
                :is-flexible      = 'isFlexible'
                :validation-state = '$v.attributesData'
              )
              product-attributes-factoring.animated.fadeIn(
                v-else-if         = 'isFactoring'
                :product-data     = 'attributesData'
                :validation-state = '$v.attributesData'
              )
              product-attributes-overdraft(
                v-else-if         = 'isOverdraft'
                :product-data     = 'attributesData'
                :validation-state = '$v.attributesData'
              )
              product-attributes.animated.fadeIn(
                v-else
                :overdraft        = 'isGuarantee'
                :leasing          = 'isLeasing'
                :laon             = 'isLoan'
                :operational      = 'isOperationalLeasing'
                :product-data     = 'attributesData'
                :validation-state = '$v.attributesData'
              )
            b-tab(
              v-if   = 'generalData.group && Object.keys(templatesData).length'
              :title = '$t("templates")'
              lazy
            )
              product-templates.animated.fadeIn(:product-data = 'templatesData')
            b-tab(
              v-if   = 'generalData.group && !isDeposit && Object.keys(scoringData).length'
              :title = '$t("scoring")'
            )
              product-scoring.animated.fadeIn(:product-data = 'scoringData')
            b-tab(
              v-if   = 'generalData.group && Object.keys(paymentsData).length'
              :title = '$t("payments")'
            )
              product-payments(
                :product-data = 'paymentsData'
                :deposit      = 'isDeposit'
              )
            b-tab(
              v-if   = 'generalData.group && financialData'
              :title = '$t("financial")'
            )
              product-financial(
                :financial-data = 'financialData'
                @update        = 'updateFinancialData'
              )
            b-tab(
              v-if   = 'generalData.group'
              :title = '$t("customFields")'
            )
              product-custom-fields(
                :custom-fields = 'customFields'
                @update       = 'updateCustomFields'
              )
            b-tab(
              v-if   = 'generalData.group && this.$route.params.group !== "DEPOSIT"'
              :title = '$t("provisioning")'
            )
              product-provisioning(
                :provision-data = 'provision'
                :deposit      = 'isDeposit'
              )
</template>


<script>
import ProductPayments from '@/views/admin/products/ProductPayments'
import { Product } from '@/models'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiSwitch from '@/components/FiSwitch'
import PercentInput from '@/components/PercentInput'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import { validation } from '@/validation'
import ProductGeneral from '@/views/admin/products/ProductGeneral'
import ProductAttributes from '@/views/admin/products/ProductAttributes'
import ProductTemplates from '@/views/admin/products/ProductTemplates'
import ProductScoring from '@/views/admin/products/ProductScoring'
import ProductAttributesDeposit from '@/views/admin/products/ProductAttributesDeposit'
import cloneDeep from 'lodash/cloneDeep'
import ProductAttributesFactoring from '@/views/admin/products/ProductAttributesFactoring'
import api from '@/api'
import { fee } from '@/helpers/feeUtils'
import ProductAttributesOverdraft from '@/views/admin/products/ProductAttributesOverdraft'
import ProductFinancial from '@/views/admin/products/ProductFinancial'
import ProductCustomFields from '@/views/admin/products/ProductCustomFields'
import ProductProvisioning from '@/views/admin/products/ProductProvisioning'
import unionBy from 'lodash/unionBy'

export default {
  name: 'product',

  components: {
    ProductAttributesOverdraft,
    ProductFinancial,
    ProductCustomFields,
    ProductProvisioning,
    ProductAttributesFactoring,
    ProductAttributesDeposit,
    ProductPayments,
    ProductScoring,
    ProductTemplates,
    ProductAttributes,
    ProductGeneral,
    FiSwitch,
    PercentInput,
    FiFormField,
    FiSelect
  },

  mixins: [validation],

  props: {
    id: {
      type: [Number, String],
      default: ''
    },
    group: {
      type: String,
      default: ''
    }
  },

  i18nOptions: {},

  data: vm => ({
    creditIssuerAccountOptions: [],
    generalData: vm.productData?.generalData || {},
    attributesData: vm.productData?.attributesData || {},
    templatesData: vm.productData?.templatesData || {},
    scoringData: vm.productData?.scoringData || {},
    paymentsData: vm.productData?.paymentsData || {},
    provision: vm.productData?.provision || {},
    financialData: vm.productData?.financialData || null,
    customFields: vm.productData?.customFields || [],
    errorPenalty: false
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName', 'optionsFromClassifier', 'classifierByCode']),
    ...mapState('classifiers', ['productTypes']),
    ...mapState('products', { product: 'current', feeConfigs: 'feeConfigs' }),
    ...mapState('settings', ['settings']),
    productGroupClassifier () {
      return this.classifierByCode('productGroups', this.group)
    },
    productData () {
      const { generalData, attributesData, templatesData, scoringData, paymentsData, provision, customFields, financialData } = this
      return (
        this.isNewProduct && Product({
          ...generalData,
          ...attributesData,
          ...templatesData,
          ...scoringData,
          ...paymentsData,
          provision,
          customFields,
          financialData,
          fees: Object.entries(this.feeConfigs)
            .filter(([feeType]) => feeType !== 'CUSTOM_FEE')
            .map(([feeType, [feeCalculationType]]) => fee({ leasing: this.leasing, feeType, feeCalculationType }))
        },
        this.optionsFromClassifier('attributeTypes', true).map(({ value }) => value)
        )
      ) ||
        cloneDeep(this.product)
    },
    isNewProduct () {
      return !this.id
    },
    header () {
      if (this.isNewProduct) {
        return this.$t('newProductTitle', { group: this.productGroupClassifier.human })
      } else {
        return this.$t('editProductTitle', { id: this.id })
      }
    },
    saving () {
      return this.$vueLoading.isLoading(this.isNewProduct ? 'product:save' : `product:${this.id}:save`)
    },
    productGroup: {
      get () {
        return this.generalData.group
      },
      set (value) {
        if (isNaN(value)) {
          this.generalData.group = value
        } else {
          this.generalData.group = this.classifierById('productGroups', value).name
        }
      }
    },
    productType () {
      return this.productTypes[this.generalData?.productType] || {}
    },
    isOverdraft () {
      return /overdraft/i.test(this.group || this.productGroup)
    },
    isGuarantee () {
      return /guarantee/i.test(this.group || this.productGroup)
    },
    isDeposit () {
      return /deposit/i.test(this.group || this.productGroup)
    },
    isFactoring () {
      return /factoring/i.test(this.group || this.productGroup)
    },
    isLeasing () {
      return /leasing/i.test(this.group || this.productGroup)
    },
    isLoan () {
      return /loan/i.test(this.group || this.productGroup)
    },
    isFlexible () {
      return /FLEXIBLE_DEPOSIT|DEPOSIT_MEMBERSHIP_CAPITAL/i.test(this.productType.code)
    },
    isFinland () {
      return /fi/i.test(this.settings.country)
    },
    isOperationalLeasing () {
      return /OPERATING/i.test(this.productType.code)
    }
  },

  watch: {
    'generalData.group': {
      async handler (value = this.group, oldValue) {
        if (oldValue) delete this.attributesData.fees
        await this.loadFeeConfig({ group: value.toUpperCase() })
        this.resetData({ group: value })
      },
      immediate: true
    },
    async 'generalData.creditIssuerId' (customerId) {
      if (!customerId) return
      const { data: { externalBankAccounts } } = await api.getCustomer({ id: customerId })
      this.creditIssuerAccountOptions = externalBankAccounts
    }
  },

  validations () {
    return this.productData.validations
  },

  async created () {
    this.loadClassifiers()
    this.isDeposit && await this.loadDepositProductClassifiers()
    if (this.isFactoring) {
      await this.loadFactoringProductClassifiers()
      await this.loadProductAttributeClassifier()
    }
    if (!this.isNewProduct) {
      if (this.isDeposit) {
        await this.loadDepositProduct({ id: this.id })
      } else if (this.isFactoring) {
        await this.loadFactoringProduct({ id: this.id })
      } else {
        await this.loadLoanProduct({ id: this.id })
      }
    }
    this.loadCreditIssuers()
    this.loadTemplates()
    this.loadProductsSettlementOrders()
    this.resetData()
  },

  methods: {
    ...mapActions('products', [
      'loadLoanProduct',
      'loadDepositProduct',
      'createLoanProduct',
      'createDepositProduct',
      'updateLoanProduct',
      'updateDepositProduct',
      'loadFactoringProduct',
      'createFactoringProduct',
      'updateFactoringProduct',
      'loadFeeConfig',
      'loadProductsSettlementOrders'
    ]),
    ...mapActions('classifiers', [
      'loadCreditIssuers',
      'loadDepositProductClassifiers',
      'loadFactoringProductClassifiers',
      'loadClassifiers',
      'loadProductAttributeClassifier',
      'loadTransactionMethods'
    ]),
    ...mapActions('templates', ['loadTemplates']),
    statusChanged ({ status }) {
      this.generalData.availableInPortal = status
    },
    async resetData ({ group = this.group } = {}) {
      Object.assign(this.$data, this.$options.data.apply(this))
      await this.loadTransactionMethods({ group: group })
      const transactionMethods = this.optionsFromClassifier('transactionMethods')
      if (this.isNewProduct) {
        if (group) {
          this.generalData.group = group
        }
        this.attributesData.currencyId = this.classifierByName('currencies', this.settings.currency)?.id
      }

      if (Object.hasOwnProperty.call(this.generalData, 'dayChangeSettlementOrder')) {
        this.generalData = {
          ...this.generalData,
          dayChangeSettlementMethod: this.generalData.dayChangeSettlementMethod ?? (transactionMethods.length === 1 ? transactionMethods[0].value : ''),
          dayChangeSettlementOrder: this.generalData.dayChangeSettlementOrder ?? this.optionsFromClassifier('dayChangeSettlementStep').map(({ value }) => value).join(',')
        }
      }
    },
    onCancel () {
      this.$router.push({ name: 'ProductList', params: { group: this.group } })
    },
    async onSubmit () {
      const { generalData, attributesData, templatesData, scoringData, paymentsData, provision, customFields, financialData } = this
      let createProduct
      let updateProduct
      const productData = Product({
        ...generalData,
        ...attributesData,
        ...templatesData,
        ...scoringData,
        ...paymentsData,
        provision: {
          ...provision,
          productId: Number(this.id)
        },
        customFields,
        financialData
      }, this.optionsFromClassifier('attributeTypes', true).map(({ value }) => value))
        .dump()
      if (this.$route.params.group === 'DEPOSIT') delete productData.provision
      const { group } = productData
      if (this.$route.params.group === 'OVERDRAFT') {
        const dayChangeSettlementOrder = productData.dayChangeSettlementOrder.split(',').map(item => item.trim())
        if (dayChangeSettlementOrder.indexOf('PENALTY') !== dayChangeSettlementOrder.length - 1 && dayChangeSettlementOrder.indexOf('PENALTY') !== 0) {
          this.errorPenalty = true
          return
        }
      }
      if (this.validate()) {
        if (this.isDeposit) {
          createProduct = this.createDepositProduct
          updateProduct = this.updateDepositProduct
          productData.group = group.toUpperCase()
        } else if (this.isFactoring) {
          createProduct = this.createFactoringProduct
          updateProduct = this.updateFactoringProduct
          productData.group = group.toUpperCase()
        } else {
          createProduct = this.createLoanProduct
          updateProduct = this.updateLoanProduct
          productData.group = group.toUpperCase()
        }

        if (this.isNewProduct) {
          await createProduct({ productData })
        } else {
          await updateProduct({
            productData,
            id: this.id
          })
          this.$toasted.success(this.$t('success'))
        }
        this.$router.push({ name: 'ProductList', params: { group: this.group } })
      }
    },
    updateFinancialData ({ field, index }) {
      const updatedFinancialData = [...this.financialData]

      updatedFinancialData.splice(index, ~index && 1, ...[field].filter(Boolean))

      this.financialData = updatedFinancialData
    },
    updateCustomFields ({ field, index, orders }) {
      const tempCustomFields = unionBy(orders, [...this.customFields], 'customFieldId')

      tempCustomFields.splice(index, ~index && 1, ...[field].filter(Boolean))

      this.customFields = tempCustomFields
    }
  }
}
</script>


<style scoped lang="scss">
.product /deep/ .fi-tab.invalid::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  padding-right: 5px;
  color: red;
  content: '\f071'
}

.card:not(:last-child) {
  margin-bottom: 0;
  border-bottom: 0;
}

table {
  th:first-child { width: 25%; }
  th:last-child { width: 15%; }
  td {
    vertical-align: middle;
    .row { padding-right: 15px; }
    .input-group {
      padding-right: 0;
      span.input-group-text { width: 72px; }
    }
  }
}

.product /deep/ .switch-status-wrap {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
}

.product /deep/ label.switch { margin-bottom: 0; }
</style>


<i18n>
en:
  newProductTitle:         "New {{ group }} product"
  editProductTitle:        "Edit product #{{id}}"
  general:                 "Product general"
  code:                    "Product code"
  name:                    "Product name"
  group:                   "Product group"
  type:                    "Product type"
  creditIssuer:            "Credit issuer"
  sector:                  "Sector"
  segment:                 "Segment"
  creditIssuerAccount:     "Credit issuer bank account"
  status:                  "Status"
  statusPortal:            "Available in portal"
  statusContracts:         "Showing contracts in portal"
  codeFieldPh:             "Enter product code"
  nameFieldPh:             "Enter product name"
  warrantyInfo:            "Warranty info"
  hirePurchaseInfo:        "Hire purchase info"
  assetTypes:              "Asset types"
  checkApr:                "APR check"
  fieldName:                "Field name"
  fieldValue:               "Field value"
  changesAllowed:          "Changes allowed"
  runAutomatically:        "Run automatically"
  templates:               "Templates"
  agreementTemplate:       "Agreement template"
  scheduleTemplate:        "Schedule template"
  offerTemplate:           "Offer template"
  infoSheetTemplate:       "Information sheet template"
  acceptTermsTemplate:     "Accept terms template"
  productAttributes:       "Product attributes"
  scoring:                 "Scoring"
  preliminaryScoringModel: "Preliminary score model"
  applicantScoringModel:   "Applicant score model"
  creditScoringModel:      "Credit score model"
  success:                 "Product changes successfully saved"
  payments:                "Payments"
  lengthError:             "This field max length is {{ value }}"
  transactionsOrder:       "Transactions order"
  transactionsMethod:      "Transactions method"
  financial:                "Financial data"
  customFields:            "Custom fields"
  provisioning:            "Provisioning"
  errorPenalty:            "Penalty can only be the first one or last one"
et:
  newProductTitle:         "New {{ group }} product"
  editProductTitle:        "Edit product #{{id}}"
  general:                 "Product general"
  code:                    "Product code"
  name:                    "Product name"
  group:                   "Product group"
  type:                    "Product type"
  creditIssuer:            "Credit issuer"
  sector:                  "Sector"
  segment:                 "Segment"
  creditIssuerAccount:     "Credit issuer bank account"
  status:                  "Status"
  statusPortal:            "Available in portal"
  statusContracts:          "Showing contracts in portal"
  codeFieldPh:             "Enter product code"
  nameFieldPh:             "Enter product name"
  warrantyInfo:            "Warranty info"
  hirePurchaseInfo:        "Hire purchase info"
  assetTypes:              "Asset types"
  checkApr:                "APR check"
  fieldName:                "Field name"
  fieldValue:               "Field value"
  changesAllowed:          "Changes allowed"
  runAutomatically:        "Run automatically"
  templates:               "Templates"
  agreementTemplate:       "Agreement template"
  scheduleTemplate:        "Schedule template"
  offerTemplate:           "Offer template"
  infoSheetTemplate:       "Information sheet template"
  acceptTermsTemplate:     "Accept terms template"
  productAttributes:       "Product attributes"
  scoring:                 "Scoring"
  preliminaryScoringModel: "Preliminary score model"
  applicantScoringModel:   "Applicant score model"
  creditScoringModel:      "Credit score model"
  success:                 "Product changes successfully saved"
  payments:                "Payments"
  lengthError:             "This field max length is {{ value }}"
  transactionsOrder:       "Transactions order"
  transactionsMethod:      "Transactions method"
  financial:                "Financial data"
  customFields:            "Custom fields"
  provisioning:            "Provisioning"
  errorPenalty:            "Penalty can only be the first one or last one"
ru:
  newProductTitle:         "New {{ group }} product"
  editProductTitle:        "Edit product #{{id}}"
  general:                 "Product general"
  code:                    "Product code"
  name:                    "Product name"
  group:                   "Product group"
  type:                    "Product type"
  creditIssuer:            "Credit issuer"
  sector:                  "Sector"
  segment:                 "Segment"
  creditIssuerAccount:     "Credit issuer bank account"
  status:                  "Status"
  statusPortal:            "Available in portal"
  statusContracts:          "Showing contracts in portal"
  codeFieldPh:             "Enter product code"
  nameFieldPh:             "Enter product name"
  warrantyInfo:            "Warranty info"
  hirePurchaseInfo:        "Hire purchase info"
  assetTypes:              "Asset types"
  checkApr:                "APR check"
  fieldName:                "Field name"
  fieldValue:               "Field value"
  changesAllowed:          "Changes allowed"
  runAutomatically:        "Run automatically"
  templates:               "Templates"
  agreementTemplate:       "Agreement template"
  scheduleTemplate:        "Schedule template"
  offerTemplate:           "Offer template"
  infoSheetTemplate:       "Information sheet template"
  acceptTermsTemplate:     "Accept terms template"
  productAttributes:       "Product attributes"
  scoring:                 "Scoring"
  preliminaryScoringModel: "Preliminary score model"
  applicantScoringModel:   "Applicant score model"
  creditScoringModel:      "Credit score model"
  success:                 "Product changes successfully saved"
  payments:                "Payments"
  lengthError:             "This field max length is {{ value }}"
  transactionsOrder:       "Transactions order"
  transactionsMethod:      "Transactions method"
  financial:                "Financial data"
  customFields:            "Custom fields"
  provisioning:            "Provisioning"
  errorPenalty:            "Penalty can only be the first one or last one"
</i18n>
