<template lang="pug">
fi-table(
  :fields = 'fields'
  :items = 'sort'
  loader = 'products:fetch'
  :empty-label = '$t("notFound")'
  responsive
)
  template(#default = '{ items }')
    product-list-item(
      v-for    = 'product in items'
      :key     = 'product.id'
      :product = 'product'
    )
</template>


<script>
import ProductListItem from './ProductListItem'
import { mapActions, mapState } from 'vuex'
import FiTable from '@/components/FiTable'
import Template from '@/views/admin/templates/Template'

export default {
  name: 'product-list',

  components: { Template, FiTable, ProductListItem },

  props: {
    group: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapState('products', ['productsList']),
    fields () {
      return [
        {
          key: 'code',
          label: this.$t('code')
        },
        {
          key: 'name',
          label: this.$t('name')
        },
        {
          key: 'type',
          label: this.$t('type')
        },
        {
          key: 'status',
          label: this.$t('status')
        }
      ]
    },
    sort () {
      const list = [...this.productsList]
      return list.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() && 1 ? -1 : 0)
    }
  },

  async created () {
    await this.loadProducts({ group: this.group.toUpperCase() })
  },

  methods: {
    ...mapActions('products', ['loadProducts'])
  }
}
</script>
