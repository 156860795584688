<template lang="pug">
fi-modal.product-financial-field-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'md'
  lazy
  @hide   = 'onHide'
  @shown  = 'resetData'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(:label = '$t("key")')
      fi-select(
        v-model = 'financialData.classifierKey'
        :options = 'fieldOptions'
      )
    fi-form-field(:label = '$t("value")')
      fi-select(
        v-model = 'selectedFieldValue'
        :options = 'fieldValues'
      )
  template(#modal-footer)
    button.btn.btn-primary(
      type           = 'submit'
      @click.prevent = 'onSubmit'
    )
      | {{ $t("common:save") }}
    button.btn.btn-secondary(@click.prevent = '$refs.modal.hide()') {{ $t("common:cancel") }}
</template>

<script>
import FiModal from '@/components/FiModal'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import pick from 'lodash/pick'

export default {
  name: 'product-financial-field-modal',
  components: { FiSelect, FiFormField, FiModal },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    financialFields: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    financialData: {
      classifierCode: '',
      classifierKey: '',
      classifierValue: '',
      id: '',
      isEnabled: false
    }
  }),

  computed: {
    selectedFieldValue: {
      get () {
        return this.financialData.id
      },
      set (fieldId) {
        const field = this.fieldValues.find(({ id }) => id === fieldId)
        this.financialData = {
          ...this.financialData,
          ...pick(field, Object.keys(this.financialData))
        }
      }
    },
    fieldOptions () {
      return Object.keys(this.financialFields)
    },
    fieldValues () {
      return this.financialFields[this.financialData.classifierKey]?.map(field => ({ ...field, value: field.id, text: field.classifierValue }))
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    onSubmit (event) {
      this.$emit('save', {
        done: () => this.$refs.modal.hide(),
        field: this.financialData
      })
    },
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this, this))
    }
  }
}
</script>


<i18n>
en:
  title: "Add financial field"
  key:   "Key"
  value: "Value"
et:
  title: "Add financial field"
  key:   "Key"
  value: "Value"
ru:
  title: "Add financial field"
  key:   "Key"
  value: "Value"
</i18n>
