<template lang="pug">
tr.product-list-item
  td: router-link(:to = '{ name: "Product", params: { group: productGroup, id: product.id.toString() } }') {{ product.code }}
  td {{ product.name }}
  td {{ productType.human }}
  td
    span.badge.badge-success(v-if = 'product.active') {{ $t('common:active') }}
    span.badge.badge-dark(v-else) {{ $t('common:inactive') }}
</template>


<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'product-list-item',

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByCode']),
    ...mapState('classifiers', ['productTypes']),
    productGroup () {
      const { group } = this.product
      if (isNaN(group)) {
        return group
      } else {
        return this.classifierById('productGroups', this.product.group).code
      }
    },
    productType () {
      const { classifier, code } = this.product.type
      return this.classifierByCode(classifier, code)
    }
  }
}
</script>
