<template lang="pug">
.animated.fadeIn.product-list-view
  .card
    .card-header {{ $t('productListTitle') }}
      router-link.float-right(:to = '{ name: "NewProduct", params: { group } }') {{ $t('newProductLink', { group: classifierByCode('productGroups', group).human }) }}
    .card-body
      b-nav.pb-2(
        content-class = 'border-0'
        pills
        lazy
      )
        b-nav-item(
          v-for = 'productGroup in productGroups'
          :to   = '{ name: "ProductGroup", params: { group: productGroup.value } }'
          :key  = 'productGroup.value'
        ) {{ $t(productGroup.text) }}
      router-view(:key = 'group')
</template>


<script>
import ProductList from '@/views/admin/products/ProductList'
import { mapGetters } from 'vuex'
import ProductListItem from './ProductListItem'

const addLoanGroup = (to, from, next) => {
  if (!to.params.group) {
    return next({ ...to, params: { group: 'LOAN' } })
  }
  next()
}

export default {
  name: 'product-list-view',

  components: {
    ProductList,
    ProductListItem
  },

  beforeRouteEnter: addLoanGroup,

  beforeRouteUpdate: addLoanGroup,

  props: {
    group: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByCode', 'classifierList']),

    productGroups () {
      return this.optionsFromClassifier('productGroups', true)
    }
  }
}
</script>


<i18n>
en:
  loan:             "Loan products"
  deposit:          "Deposit products"
  overdraft:        "Overdraft products"
  leasing:          "Leasing products"
  factoring:        "Factoring products"
  guarantee:        "Guarantee products"
  productListTitle: "Products"
  notFound:         "No products found"
  newProductLink:   "+ New {{ group }} product"
  code:             "Product code"
  name:             "Product name"
  type:             "Product type"
  status:           "Status"
et:
  loan:             "Loan products"
  deposit:          "Deposit products"
  overdraft:        "Overdraft products"
  leasing:          "Leasing products"
  factoring:        "Factoring products"
  guarantee:        "Guarantee products"
  productListTitle: "Products"
  notFound:         "No products found"
  newProductLink:   "+ New {{ group }} product"
  code:             "Product code"
  name:             "Product name"
  type:             "Product type"
  status:           "Status"
ru:
  loan:             "Loan products"
  deposit:          "Deposit products"
  overdraft:        "Overdraft products"
  leasing:          "Leasing products"
  factoring:        "Factoring products"
  guarantee:        "Guarantee products"
  productListTitle: "Products"
  notFound:         "No products found"
  newProductLink:   "+ New {{ group }} product"
  code:             "Product code"
  name:             "Product name"
  type:             "Product type"
  status:           "Status"
</i18n>
