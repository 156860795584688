<template lang="pug">
tr
  td {{ $t('amount') }}
  td
    .row
      .col-2
        slot(v-if = '!removable')
      .col
        fi-form-field.mb-0(:state = '!amountRange.$anyError')
          template(#error)
            .row
              .col
                .error-message(v-if = '!amountRange.amountRange.from.required && amountRange.amountRange.from.$error') {{ $t('common:required') }}
              .col
                .error-message(v-if = '!amountRange.amountRange.to.required && amountRange.amountRange.to.$error') {{ $t('common:required') }}
              .col
                .error-message(v-if = '!amountRange.sliderStep.required && amountRange.sliderStep.$error') {{ $t('common:required') }}
                .error-message(v-if = '!amountRange.sliderStep.steps') {{ $t('stepsError')}}
          .row
            .input-group.input-group-sm.col-md
              .input-group-prepend: span.input-group-text {{ $t('from') }}
              input.form-control.form-control-sm(
                v-model.number = 'amountRange.amountRange.from.$model'
                :class         = '{ "is-invalid": amountRange.amountRange.from.$error || amountRange.amountRange.$error }'
              )
            .input-group.input-group-sm.col-md
              .input-group-prepend: span.input-group-text {{ $t('to') }}
              input.form-control.form-control-sm(
                v-model.number = 'amountRange.amountRange.to.$model'
                :class         = '{ "is-invalid": amountRange.amountRange.to.$error }'
              )
            .input-group.input-group-sm.col-md
              .input-group-prepend: span.input-group-text {{ $t('step') }}
              input.form-control.form-control-sm(
                v-model.number = 'amountRange.sliderStep.$model'
                :class         = '{ "is-invalid": amountRange.sliderStep.$error }'
              )
  td
    button.btn.btn-link.text-danger.animated.fadeIn.btn-sm(
      v-if           = 'removable'
      @click.prevent = '$emit("remove")'
    )
      i.far.fa-trash-alt.fa-lg
    button.btn.btn-link.btn-sm(
      v-else
      @click.prevent = '$emit("add")'
    )
      i.far.fa-plus-square.fa-lg
</template>


<script>
import FiFormField from '@/components/FiFormField'

export default {
  name: 'product-amount-range-item',

  components: { FiFormField },

  props: {
    amountRange: {
      type: Object,
      required: true
    },
    removable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
