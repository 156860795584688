<template lang="pug">
fi-modal.product-custom-field-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'md'
  lazy
  @shown  = 'resetData'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(:label = '$t("field")')
      fi-select(
        v-model = 'customFieldData.customFieldId'
        :options = 'sortClassifiers'
        label-by = 'name'
        value-by = 'id'
        required
      )
    fi-form-field(:label = '$t("order")')
      input.form-control.form-control-sm(
        type           = 'number'
        v-model.number = 'customFieldData.fieldOrder'
      )
    fi-form-field(:label = '$t("application")')
      fi-switch(
        v-model = 'customFieldData.enabledApplication'
      )
    fi-form-field(:label = '$t("portal")')
      fi-switch(
        v-model = 'customFieldData.enabledPortal'
      )
  template(#modal-footer)
    button.btn.btn-primary(
      type           = 'submit'
      @click.prevent = 'onSubmit'
    )
      | {{ $t("common:save") }}
    button.btn.btn-secondary(@click.prevent = '$refs.modal.hide()') {{ $t("common:cancel") }}
</template>


<script>
import FiModal from '@/components/FiModal'
import FiSwitch from '@/components/FiSwitch'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'

export default {
  name: 'product-custom-field-modal',

  components: { FiSelect, FiFormField, FiSwitch, FiModal },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    fieldOptions: {
      type: Array,
      required: true
    },
    field: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data: () => ({
    customFieldData: {
      customFieldId: '',
      enabledApplication: false,
      enabledPortal: false,
      fieldOrder: 0
    }
  }),

  computed: {
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    sortClassifiers () {
      const list = JSON.parse(JSON.stringify(this.fieldOptions))
      const sortList = list.sort(function (a, b) {
        const nameA = a.name
        const nameB = b.name
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    }
  },

  methods: {
    onSubmit (event) {
      this.$emit('save', {
        done: () => this.$refs.modal.hide(),
        field: this.customFieldData
      })
    },
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this, this))
    }
  }

}
</script>


<i18n>
en:
  title:       "Add product custom field"
  field:        "Field"
  order:       "Order"
  portal:      "Portal"
  application: "Application"
  contract:    "Contract"
et:
  title:       "Add product custom field"
  field:        "Field"
  order:       "Order"
  portal:      "Portal"
  application: "Application"
  contract:    "Contract"
ru:
  title:       "Add product custom field"
  field:        "Field"
  order:       "Order"
  portal:      "Portal"
  application: "Application"
  contract:    "Contract"
</i18n>
