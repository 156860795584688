<template lang="pug">
  tr
    td {{ title.human }}
    td: fi-form-field.mb-0(:state = '!attribute.$anyError')
      template(#error)
        .row
          .col-md(v-if = 'hasField("invoicingDay")')
            .error-message(v-if = '!attribute.invoicingDay.required') {{ $t('common:required') }}
          .col-md(v-if = 'hasField("periodUnitType")')
            .error-message(v-if = '!attribute.periodUnitType.required') {{ $t('common:required') }}
          .col-md(v-if = 'hasField("dayCountConvention")')
            .error-message(v-if = '!attribute.dayCountConvention.required') {{ $t('common:required') }}
          .col-md(v-if = 'hasField("paymentTerm")')
            .error-message(v-if = '!attribute.paymentTerm.required') {{ $t('common:required') }}

          .col-md(v-if = 'hasField("defaultValue")')
            .error-message(v-if = '!attribute.defaultValue.required') {{ $t('common:required') }}
            .error-message(v-if = '!attribute.defaultValue.min') {{ $t('minError', { value: attribute[attribute.defaultValue.$params.min.param].$model }) }}
            .error-message(v-if = '!attribute.defaultValue.max') {{ $t('maxError', { value: attribute[attribute.defaultValue.$params.max.param].$model }) }}

          .col-md(v-if = 'hasField("minValue")')
            .error-message(v-if = '!attribute.minValue.required') {{ $t('common:required') }}
            .error-message(v-if = '!attribute.minValue.max') {{ $t('maxError', { value: attribute[attribute.minValue.$params.max.param].$model }) }}

          .col-md(v-if = 'hasField("maxValue")')
            .error-message(v-if = '!attribute.maxValue.required') {{ $t('common:required') }}
            .error-message(v-if = '!attribute.maxValue.min') {{ $t('minError', { value: attribute[attribute.maxValue.$params.min.param].$model }) }}

          .col-md(v-if = 'hasField("step")')
            .error-message(v-if = '!attribute.step.required') {{ $t('common:required') }}
            .error-message(v-if = '!attribute.step.steps') {{ $t('stepsError')}}
      .row
        .col-md(v-if = 'hasField("periodUnitType")')
          fi-select(
            v-model.number = 'attribute.periodUnitType.$model'
            :options     = 'periodUnitOptions'
            :class         = '{ "is-invalid": attribute.periodUnitType.$error }'
            required
            sm
          )
        .col-md(v-if = 'hasField("dayCountConvention")')
          fi-select(
            v-model.number = 'attribute.dayCountConvention.$model'
            :options     = 'dayCountConventionOptions'
            :class         = '{ "is-invalid": attribute.dayCountConvention.$error }'
            required
            sm
          )
        .col-md(v-if = 'hasField("paymentTerm")')
          input.form-control.form-control-sm(
            v-model.number = 'attribute.paymentTerm.$model'
            :class         = '{ "is-invalid": attribute.paymentTerm.$error }'
          )
        .col-md(v-if = 'hasField("invoicingDay")')
          input.form-control.form-control-sm(
            v-model.number = 'attribute.invoicingDay.$model'
            :class         = '{ "is-invalid": attribute.invoicingDay.$error }'
          )
        .input-group.col-md.input-group-sm(v-if = 'hasField("defaultValue")')
          .input-group-prepend
            span.input-group-text {{ $t('common:default') }}
          percent-input.form-control.form-control-sm(
            v-if           = 'isPercentAttribute'
            v-model.number = 'attribute.defaultValue.$model'
            :class         = '{ "is-invalid": attribute.defaultValue.$error }'
          )
          input.form-control.form-control-sm(
            v-else
            v-model.number = 'attribute.defaultValue.$model'
            :class         = '{ "is-invalid": attribute.defaultValue.$error }'
          )
        .input-group.col-md.input-group-sm(v-if = 'hasField("minValue")')
          .input-group-prepend
            span.input-group-text {{ $t('common:min') }}
          percent-input.form-control.form-control-sm(
            v-if           = 'isPercentAttribute'
            v-model.number = 'attribute.minValue.$model'
            :class         = '{ "is-invalid": attribute.minValue.$error }'
          )
          input.form-control.form-control-sm(
            v-else
            v-model.number = 'attribute.minValue.$model'
            :class         = '{ "is-invalid": attribute.minValue.$error }'
          )
        .input-group.col-md.input-group-sm(v-if = 'hasField("maxValue")')
          .input-group-prepend
            span.input-group-text {{ $t('common:max') }}
          percent-input.form-control.form-control-sm(
            v-if           = 'isPercentAttribute'
            v-model.number = 'attribute.maxValue.$model'
            :class         = '{ "is-invalid": attribute.maxValue.$error }'
          )
          input.form-control.form-control-sm(
            v-else
            v-model.number = 'attribute.maxValue.$model'
            :class         = '{ "is-invalid": attribute.maxValue.$error }'
          )
        .input-group.col-md.input-group-sm(v-if = 'hasField("step")')
          .input-group-prepend
            span.input-group-text {{ $t('common:step') }}
          input.form-control.form-control-sm(
            v-model.number = 'attribute.step.$model'
            :class         = '{ "is-invalid": attribute.step.$error }'
          )
    td: fi-switch(
      v-if    = 'hasField("changesAllowed")'
      v-model = 'attribute.changesAllowed.$model'
    )

</template>
<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { mapGetters } from 'vuex'
import PercentInput from '@/components/PercentInput'

export default {
  name: 'product-attribute-item',
  components: {
    PercentInput,
    FiFormField,
    FiSelect,
    FiSwitch
  },
  props: {
    attribute: {
      type: Object,
      required: true
    },
    periodUnitOptions: {
      type: Array,
      required: true
    },
    dayCountConventionOptions: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('classifiers', ['classifierByName', 'optionsFromClassifier']),
    title () {
      return this.classifierByName('AttributeType', this.attribute.type.$model) ?? {}
    },
    isPercentAttribute () {
      return /^(?!.*amount).*rate?/i.test(this.attribute.type.$model)
    }
  },
  methods: {
    hasField (field) {
      return Object.hasOwnProperty.call(this.attribute.$model, field)
    }
  }
}
</script>
