<template lang='pug'>
.product-attributes-deposit
  table.table
    thead: tr
      th {{ $t('fieldName') }}
      th {{ $t('fieldValue') }}
      th
    tbody
      tr
        td {{ $t('dayCountConvention') }}
        td: fi-form-field.mb-0(:state = '!validationState.dayCountConventionType.$error')
          template(#error)
            .error-message(v-if = '!validationState.dayCountConventionType.required') {{ $t('common:required') }}
          fi-select(
            v-model.number = 'validationState.dayCountConventionType.$model'
            :options       = 'optionsFromClassifier("dayCountConvention")'
            :class         = '{ "is-invalid": validationState.dayCountConventionType.$error }'
            sm
          )
        td
      tr
        td {{ $t('dailyCalculation') }}
        td: fi-form-field.mb-0(:state = '!validationState.dailyBalanceCalcType.$error')
          template(#error)
            .error-message(v-if = '!validationState.dailyBalanceCalcType.required') {{ $t('common:required') }}
          fi-select(
            v-model.number = 'validationState.dailyBalanceCalcType.$model'
            :options       = 'optionsFromClassifier("dailyBalanceCalcType")'
            :class         = '{ "is-invalid": validationState.dailyBalanceCalcType.$error }'
            sm
          )
        td
      tr
        td.align-top {{ $t('interestsConstraints') }}
        td: fi-form-field.mb-0(:state = '!validationState.interestRateConstraintsGroup.$error')
          template(#error)
            .row
              .col
                .error-message(v-if = '!validationState.interestRateMin.required && validationState.interestRateMin.$error') {{ $t('common:required') }}
              .col
                .error-message(v-if = '!validationState.interestRateMax.required && validationState.interestRateMax.$error') {{ $t('common:required') }}
          .row
            .input-group.input-group-sm.col-md-6
              .input-group-prepend
                span.input-group-text {{ $t('common:min') }}
              percent-input.form-control.form-control-sm(
                v-model.number = 'validationState.interestRateMin.$model'
                :class         = '{ "is-invalid": validationState.interestRateMin.$error }'
              )
            .input-group.input-group-sm.col-md-6
              .input-group-prepend
                span.input-group-text {{ $t('common:max') }}
              percent-input.form-control.form-control-sm(
                v-model.number = 'validationState.interestRateMax.$model'
                :class         = '{ "is-invalid": validationState.interestRateMax.$error }'
              )
        td
      template(v-for = '(amountRange, index) in validationState.amountRangePeriodInterests.$each.$iter')
        product-amount-range-item.animated.fadeIn(
          :amount-range     = 'amountRange'
          :removable        = 'index > 0'
          @remove           = 'removeAmountRangeRow(index)'
          @add              = 'addAmountRangeRow'
        )
          fi-form-field.mb-0(:state = '!validationState.currencyId.$error')
            template(#error)
              .error-message(v-if = '!validationState.currencyId.required') {{ $t('common:required') }}
            fi-select(
              v-model.number = 'validationState.currencyId.$model'
              :options       = 'optionsFromClassifier("currencies")'
              :class         = '{ "is-invalid": validationState.currencyId.$error }'
              sm
            )
        tr.animated.fadeIn
          td.border-top-0.align-top {{ $t('interestsByPeriod') }}
          td.border-top-0
            .row
              .col-2
                fi-form-field.mb-0(
                  v-if = '!Number(index)'
                  :state = '!validationState.periodUnitType.$error'
                )
                  template(#error)
                    .error-message(v-if = '!validationState.periodUnitType.required') {{ $t('common:required') }}
                  fi-select(
                    v-model.number = 'validationState.periodUnitType.$model'
                    :options       = 'optionsFromClassifier("periodUnitType", true)'
                    :class         = '{ "is-invalid": validationState.periodUnitType.$error }'
                    sm
                  )
              .col
                fi-form-field.animated.fadeIn(
                  v-for  = '(periodRangeInterest, index) in amountRange.periodRangeInterests.$each.$iter'
                  :key   = 'index'
                  :state = '!periodRangeInterest.$anyError'
                )
                  template(#error)
                    .row
                      .col
                        .error-message(v-if = '!periodRangeInterest.periodRange.from.required') {{ $t('common:required') }}
                        .error-message(v-if = '!periodRangeInterest.periodRange.from.min') {{ $t('minValueError', { value: periodRangeInterest.periodRange.from.$params.min.min }) }}
                      .col
                        .error-message(v-if = '!periodRangeInterest.periodRange.to.required') {{ $t('common:required') }}
                      .col
                        .error-message(v-if = '!periodRangeInterest.interest.required') {{ $t('common:required') }}
                        .error-message(v-if = '!periodRangeInterest.interest.min') {{ $t('minValueError', { value: $options.filters.percent(validationState.interestRateMin.$model) }) }}
                        .error-message(v-if = '!periodRangeInterest.interest.max') {{ $t('maxValueError', { value: $options.filters.percent(validationState.interestRateMax.$model) }) }}
                  .row
                    .input-group.input-group-sm.col-md-4
                      .input-group-prepend
                        span.input-group-text {{ $t('from') }}
                      input.form-control.form-control-sm(
                        v-model.number = 'periodRangeInterest.periodRange.from.$model'
                        :class         = '{ "is-invalid": periodRangeInterest.periodRange.from.$error }'
                      )
                    .input-group.input-group-sm.col-md-4
                      .input-group-prepend
                        span.input-group-text {{ $t('to') }}
                      input.form-control.form-control-sm(
                        v-model.number = 'periodRangeInterest.periodRange.to.$model'
                        :class         = '{ "is-invalid": periodRangeInterest.periodRange.to.$error }'
                      )
                    .input-group.input-group-sm.col-md-3
                      .input-group-prepend
                        span.input-group-text {{ $t('interest') }}
                      percent-input.form-control.form-control-sm(
                        v-model.number = 'periodRangeInterest.interest.$model'
                        :class         = '{ "is-invalid": periodRangeInterest.interest.$error }'
                      )
                    .input-group.input-group-sm.col-md-1
                      button.btn.btn-link.text-danger.btn-sm(
                        v-if           = 'index > 0'
                        @click.prevent = 'removeInterestPeriodRow(amountRange.periodRangeInterests.$model, index)'
                      )
                        i.far.fa-trash-alt.fa-lg
                      button.btn.btn-link.btn-sm(
                        v-else
                        @click.prevent = 'addInterestPeriodRow(amountRange.periodRangeInterests.$model)'
                      )
                        i.far.fa-plus-square.fa-lg
      tr
        td.align-top {{ $t('paymentFrequency') }}
        td: fi-form-field.mb-0(:state = '!validationState.interestPaymentMethods.$error')
          .row.animated.fadeIn(v-for = "(paymentMethod, index) in validationState.interestPaymentMethods.$each.$iter")
            .input-group.input-group-sm.col-md-6
              fi-select(
                v-model = 'paymentMethod.type.code.$model'
                :options       = 'optionsFromClassifier("interestPaymentMethod")'
                :disabled      = '!Number(index)'
                :class         = '{ "is-invalid": paymentMethod.type.$error }'
                sm
              )
            .input-group.input-group-sm.col-md-5
              .input-group-prepend
                span.input-group-text {{ $t('deduction') }}
              percent-input.form-control.form-control-sm(
                v-model.number = 'paymentMethod.deduction.$model'
                :disabled      = '!Number(index)'
                :class         = '{ "is-invalid": paymentMethod.deduction.$error }'
              )
            .input-group.input-group-sm.col-md-1()
              button.btn.btn-link.text-danger.btn-sm(
                v-if = 'Number(index)'
                @click.prevent = 'removePaymentMethodRow(index)'
              )
                i.far.fa-trash-alt.fa-lg

              button.btn.btn-link.btn-sm(
                v-else
                @click.prevent = 'addPaymentMethodRow'
              )
                i.far.fa-plus-square.fa-lg
        td
      template(v-if = 'isFlexible')
        tr
          td {{ $t('withdrawalAmountMaxCurrent') }}
          td: fi-form-field.mb-0(:state = '!validationState.maxWithdrawalFromCurrentAmount.$error')
            template(#error)
              .error-message(v-if = '!validationState.maxWithdrawalFromCurrentAmount.required') {{ $t('common:required') }}
            percent-input.form-control.form-control-sm(
              v-model.number = 'validationState.maxWithdrawalFromCurrentAmount.$model'
              :class         = '{ "is-invalid": validationState.maxWithdrawalFromCurrentAmount.$error }'
            )
          td
      tr
        td {{ $t('compoundInterest') }}
        td: fi-form-field.mb-0
          .switch-status-wrap
            fi-switch(v-model = 'validationState.compoundInterest.$model')
        td
      tr
        td {{ $t('automaticallyExtendable') }}
        td: fi-form-field.mb-0
          .switch-status-wrap
            fi-switch(v-model = 'autoExtendable')
        td
      tr.animated.fadeIn(v-if = 'autoExtendable')
        td {{ $t('automaticExtendInterest') }}
        td: fi-form-field.mb-0(:state = '!validationState.autoExtendable.additionalInterest.$error')
          template(#error)
            .error-message(v-if = '!validationState.autoExtendable.additionalInterest.required') {{ $t('common:required') }}
          percent-input.form-control.form-control-sm(
            v-model.number = 'validationState.autoExtendable.additionalInterest.$model'
            :class         = '{ "is-invalid": validationState.autoExtendable.additionalInterest.$error }'
          )
        td
      tr
        td {{ $t('withholdTax') }}
        td: fi-form-field.mb-0
          .switch-status-wrap
            fi-switch(v-model = 'validationState.withholdTaxEnabled.$model')
        td
      tr
        td {{ $t('standbyTime') }}
        td: fi-form-field.mb-0(:state = '!validationState.hoursForActivation.$error')
          template(#error)
            .error-message(v-if = '!validationState.hoursForActivation.required') {{ $t('common:required') }}
          input.form-control.form-control-sm(
            v-model.number = 'validationState.hoursForActivation.$model'
            :class         = '{ "is-invalid": validationState.hoursForActivation.$error }'
          )
        td
      tr
        td {{ $t('originMoneyLimit') }}
        td: fi-form-field.mb-0(:state = '!validationState.originOfMoneyLimit.$error')
          template(#error)
            .error-message(v-if = '!validationState.originOfMoneyLimit.required') {{ $t('common:required') }}
          input.form-control.form-control-sm(
            v-model.number = 'validationState.originOfMoneyLimit.$model'
            :class         = '{ "is-invalid": validationState.originOfMoneyLimit.$error }'
          )
        td
      tr
        td.fee-row(colspan = 3)
          .fee-title {{ $t('fees') }}
          table.table.fee-table
            thead: tr
              th {{ $t('feeTableTitle') }}
              th {{ $t('feeCalculationMethod') }}
              th {{ $t('common:default') }}
              th {{ $t('common:min') }}
              th {{ $t('common:max') }}
              th {{ $t('changesAllowed') }}
            tbody
              product-fee-item.animated.fadeIn(
                v-for         = '(fee, index) in validationState.fees.$each.$iter'
                :key          = 'index'
                :fee          = 'fee'
                :removable    = '!feeRequired(fee)'
                :product-data = 'productData'
                :type-options = 'feeTypeOptions'
                :required     = 'feeRequired(fee)'
                :fee-configs  = 'feeConfigs'
                @remove-fee   = 'removeFee(index)'
              )
      tr(v-if = 'feeTypeOptions.length')
        td.border-top-0.text-center(colspan = 3)
          button.btn.btn-link(@click.prevent = 'addFee') {{ $t('addFee') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import PercentInput from '@/components/PercentInput'
import ProductAmountRangeItem from '@/views/admin/products/ProductAmountRangeItem'
import ProductFeeItem from '@/views/admin/products/ProductFeeItem'
import { mapGetters, mapState } from 'vuex'
import differenceWith from 'lodash/differenceWith'
import { GUARANTEE_FEE, GUARANTEE_FEE_BY_GOV } from './variables'

const fee = () => ({
  feeType: {
    classifier: 'feeTypeEntity',
    code: ''
  },
  feeAmount: undefined,
  feeMax: undefined,
  feeMin: undefined,
  feeCalculationType: {
    classifier: 'feeCalculationType',
    code: ''
  },
  feeOverride: false
})


export default {
  name: 'product-attributes-deposit',

  components: {
    ProductFeeItem,
    ProductAmountRangeItem,
    FiSwitch,
    PercentInput,
    FiFormField,
    FiSelect
  },

  props: {
    productData: {
      type: Object,
      required: true
    },
    productGroup: {
      type: String,
      required: true
    },
    isFlexible: {
      type: Boolean,
      default: false
    },
    validationState: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('products', ['feeConfigs']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByName', 'classifierList']),
    autoExtendable: {
      get () {
        return this.productData.autoExtendable?.extendable
      },
      set (value) {
        if (value) {
          this.$set(this.productData, 'autoExtendable', {
            extendable: value,
            additionalInterest: undefined
          })
        } else {
          this.productData.autoExtendable = {
            extendable: false
          }
        }
      }
    },
    feeTypeOptions () {
      return differenceWith(
        this.optionsFromClassifier('feeTypeEntity', true),
        this.validationState.fees.$model,
        (option, fee) => option.value === fee.feeType.code).filter((el) => el.text !== GUARANTEE_FEE && el.text !== GUARANTEE_FEE_BY_GOV)
    }
  },

  mounted () {
    if (this.productData.amountRangePeriodInterests?.length === 0) {
      this.addAmountRangeRow()
    }

    if (this.productData.productServiceFees?.length === 0) {
      this.addFee()
    }
  },

  methods: {
    addFee () {
      const newFee = fee()
      this.productData.fees.push(newFee)
    },
    addInterestPeriodRow (periodRangeInterest) {
      periodRangeInterest.push({
        periodRange: {
          from: undefined,
          to: undefined
        },
        interest: undefined
      })
    },
    addPaymentMethodRow () {
      this.productData.interestPaymentMethods.push({
        type: {
          code: undefined
        },
        deduction: undefined
      })
    },
    addAmountRangeRow () {
      this.productData.amountRangePeriodInterests.push({
        amountRange: {
          from: undefined,
          to: undefined
        },
        sliderStep: undefined,
        periodRangeInterests: [
          {
            periodRange: {
              from: undefined,
              to: undefined
            },
            interest: undefined
          }
        ]
      })
    },
    removeAmountRangeRow (index) {
      this.productData.amountRangePeriodInterests.splice(index, 1)
    },
    removeInterestPeriodRow (periodRangeInterests, index) {
      periodRangeInterests.splice(index, 1)
    },
    removePaymentMethodRow (index) {
      this.productData.interestPaymentMethods.splice(index, 1)
    },
    removeFee (id) {
      this.productData.fees.splice(id, 1)
    },
    feeRequired (fee) {
      return ['TERMINATION', 'WITHDRAWAL'].some(feeType => new RegExp(feeType, 'i').test(fee.feeType.code.$model))
    }
  }
}
</script>


<style lang="scss" scoped>
  .fi-form-field-value > .row {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0!important;
    }
  }

  .product-attributes-deposit {
    .fee-row {
      padding: 0.75rem 0;

      .fee-title {
        padding: 0.75rem;
      }

      .fee-table {
        & /deep/ thead, tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }
      }
    }
  }
</style>


<i18n>
en:
  amount:                    "Amount"
  currency:                  "Currency"
  dayCountConvention:         "Interest day count convention"
  dailyCalculation:           "Daily balance calculation"
  timePeriod:                 "Time period unit"
  interestsByPeriod:          "Deposit interests by period"
  interestsConstraints:       "Interest constraints"
  paymentFrequency:           "Interest payment frequency"
  withdrawalAmountMaxCurrent: "Maximum withdrawal amount (rate of current amount)"
  withholdTax:                "Withhold tax"
  compoundInterest:           "Compound interest"
  automaticallyExtendable:    "Automatically extendable"
  automaticExtendInterest:    "Additional interest in case of automatic extension"
  standbyTime:                "Standby time to activate the deposit"
  originMoneyLimit:           "Origin of money limit"
  fees:                       "Product fees"
  addFee:                     "+ Add new fee"
  interest:                   "Interest"
  from:                       "From"
  to:                         "To"
  step:                       "Step"
  minValueError:              "This field minimum value is {{ value }}"
  maxValueError:              "This field maximum value is {{ value }}"
  feeTableTitle:              "Fee name"
  feeCalculationMethod:       "Calculation method"
  stepsError:                 "Number of steps should be integer"
et:
  amount:                    "Amount"
  currency:                  "Currency"
  dayCountConvention:         "Interest day count convention"
  dailyCalculation:           "Daily balance calculation"
  timePeriod:                 "Time period unit"
  interestsByPeriod:          "Deposit interests by period"
  interestsConstraints:       "Interest constraints"
  paymentFrequency:           "Interest payment frequency"
  withdrawalAmountMaxCurrent: "Maximum withdrawal amount (rate of current amount)"
  withholdTax:                "Withhold tax"
  paymentDay:                 "Day of payment"
  compoundInterest:           "Compound interest"
  automaticallyExtendable:    "Automatically extendable"
  automaticExtendInterest:    "Additional interest in case of automatic extension"
  standbyTime:                "Standby time to activate the deposit"
  originMoneyLimit:           "Origin of money limit"
  fees:                       "Product fees"
  addFee:                     "+ Add new fee"
  interest:                   "Interest"
  from:                       "From"
  to:                         "To"
  step:                       "Step"
  minValueError:              "This field minimum value is {{ value }}"
  maxValueError:              "This field maximum value is {{ value }}"
  feeTableTitle:              "Fee name"
  feeCalculationMethod:       "Calculation method"
  stepsError:                 "Number of steps should be integer"
ru:
  amount:                    "Amount"
  currency:                  "Currency"
  dayCountConvention:         "Interest day count convention"
  dailyCalculation:           "Daily balance calculation"
  timePeriod:                 "Time period unit"
  interestsByPeriod:          "Deposit interests by period"
  interestsConstraints:       "Interest constraints"
  paymentFrequency:           "Interest payment frequency"
  withdrawalAmountMaxCurrent: "Maximum withdrawal amount (rate of current amount)"
  withholdTax:                "Withhold tax"
  paymentDay:                 "Day of payment"
  compoundInterest:           "Compound interest"
  automaticallyExtendable:    "Automatically extendable"
  automaticExtendInterest:    "Additional interest in case of automatic extension"
  standbyTime:                "Standby time to activate the deposit"
  originMoneyLimit:           "Origin of money limit"
  fees:                       "Product fees"
  addFee:                     "+ Add new fee"
  interest:                   "Interest"
  from:                       "From"
  to:                         "To"
  step:                       "Step"
  minValueError:              "This field minimum value is {{ value }}"
  maxValueError:              "This field maximum value is {{ value }}"
  feeTableTitle:              "Fee name"
  feeCalculationMethod:       "Calculation method"
</i18n>
