  <template lang='pug'>
.product-attributes.table-responsive.mb-0
  table.table
    thead: tr
      th {{ $t('fieldName') }}
      th {{ $t('fieldValue') }}
      th {{ $t('changesAllowed') }}
    tbody
      tr
        td {{ $t('currency') }}
        td
          fi-form-field.mb-0(:state = '!validationState.currencyId.$error')
            template(#error)
              .error-message(v-if = '!validationState.currencyId.required') {{ $t('common:required') }}
            fi-select(
              v-model.number = 'validationState.currencyId.$model'
              :options       = 'optionsFromClassifier("currencies")'
              :class         = '{ "is-invalid": validationState.currencyId.$error }'
              required
              sm
            )
      ProductAttributeItem(
        v-for                         = '(attribute, key) in validationState.attributes.$each.$iter'
        :key                          = 'key'
        :attribute                    = 'attribute'
        :period-unit-options          = 'periodUnitOptions'
        :day-count-convention-options = 'dayCountConventionOptions'
      )
      tr
        td.fee-row(:colspan = '3')
          .fee-title {{ $t('fees') }}
          table.table.fee-table
            thead: tr
              th {{ $t('feeTableTitle') }}
              th {{ $t('feeCalculationMethod') }}
              th {{ $t('common:default') }}
              th {{ $t('common:min') }}
              th {{ $t('common:max') }}
              th {{ $t('changesAllowed') }}
            tbody
              product-fee-item.animated.fadeIn(
                v-for         = '(fee, index) in validationState.fees.$each.$iter'
                :key          = 'index'
                :fee          = 'fee'
                :removable    = '(validationState.fees.$model.length > validationState.fees.$params.minLength.min) && !feeRequired(fee)'
                :product-data = 'productData'
                :type-options = 'feeTypeOptions'
                :required     = 'feeRequired(fee)'
                :fee-configs  = 'feeConfigs'
                @remove-fee   = 'removeFee(index)'
              )
      tr(v-if = 'feeTypeOptions.length')
        td.border-top-0.text-center(colspan='3')
          button.btn.btn-link(@click.prevent = 'addFee') {{ $t('addFee') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import { formatRangeError } from '@/mixins'
import ProductFeeItem from '@/views/admin/products/ProductFeeItem'
import differenceWith from 'lodash/differenceWith'
import { mapGetters, mapState } from 'vuex'
import ProductAttributeItem from '@/views/admin/products/ProductAttributeItem'
import { GUARANTEE_FEE, GUARANTEE_FEE_BY_GOV } from './variables'

const fee = () => ({
  feeType: {
    classifier: 'feeTypeEntity',
    code: ''
  },
  feeAmount: undefined,
  feeMax: undefined,
  feeMin: undefined,
  feeCalculationType: {
    classifier: 'feeCalculationType',
    code: ''
  },
  feeOverride: false
})

export default {
  name: 'product-attributes-factoring',

  components: {
    ProductAttributeItem,
    ProductFeeItem,
    FiFormField,
    FiSelect
  },

  mixins: [formatRangeError],

  props: {
    validationState: {
      type: Object,
      required: true
    },
    productData: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('products', ['feeConfigs']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByName', 'classifierList']),
    principalPaymentPeriods () {
      return this.optionsFromClassifier('paymentPeriodTypes').filter(period => period.value >= this.productData.interestPaymentPeriodId)
    },
    periodUnitOptions () {
      return Object.values(this.classifierList('periodUnitType'))
        .filter(({ productGroups }) => productGroups.includes('FACTORING'))
        .map(({ code, human }) => ({ text: human, value: code }))
    },
    dayCountConventionOptions () {
      return this.optionsFromClassifier('dayCountConventions', true)
    },
    feeTypeOptions () {
      return differenceWith(
        this.optionsFromClassifier('feeTypeEntity', true),
        this.validationState.fees.$model,
        (option, fee) => option.value === fee.feeType.code).filter((el) => el.text !== GUARANTEE_FEE && el.text !== GUARANTEE_FEE_BY_GOV)
    }
  },

  methods: {
    addFee () {
      this.productData.fees = [...this.productData.fees, fee()]
    },
    removeFee (id) {
      this.productData.fees.splice(id, 1)
    },
    feeRequired (fee) {
      return /AGREEMENT|TERMINATION|ADMINISTRATION|PROCESSING/.test(fee.feeType.code.$model)
    }
  }
}
</script>


<style lang="scss" scoped>
.product-attributes {
  .fee-row {
    padding: 0.75rem 0;

    .fee-title {
      padding: 0.75rem;
    }

    .fee-table {
      & /deep/ thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
    }
  }
}
</style>


<i18n>
en:
  currency:             "Currency"
  fees:                 "Product fees"
  addFee:               "+ Add new fee"
  minError:             "Value should be greater or equal to {{ value }}"
  maxError:             "Value should be less or equal to {{ value }}"
  feeTableTitle:        "Fee name"
  feeCalculationMethod: "Calculation method"
  stepsError:           "Number of steps should be integer"
et:
  currency:             "Currency"
  fees:                 "Product fees"
  addFee:               "+ Add new fee"
  minError:             "Value should be greater or equal to {{ value }}"
  maxError:             "Value should be less or equal to {{ value }}"
  feeTableTitle:        "Fee name"
  feeCalculationMethod: "Calculation method"
  stepsError:           "Number of steps should be integer"
ru:
  currency:             "Currency"
  fees:                 "Product fees"
  addFee:               "+ Add new fee"
  minError:             "Value should be greater or equal to {{ value }}"
  maxError:             "Value should be less or equal to {{ value }}"
  feeTableTitle:        "Fee name"
  feeCalculationMethod: "Calculation method"
  stepsError:           "Number of steps should be integer"
</i18n>
