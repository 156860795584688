  <template lang='pug'>
.product-attributes.table-responsive.mb-0
  table.table
    thead: tr
      th {{ $t('fieldName') }}
      th {{ $t('fieldValue') }}
      th {{ $t('changesAllowed') }}
      th(v-if = 'leasing') {{ $t('vat') }}
    tbody
      tr(v-if = '!overdraft')
        td {{ $t('scheduleType') }}
        td: fi-form-field.mb-0(:state = '!validationState.scheduleTypeId.$anyError')
          template(#error)
            .error-message(v-if = '!validationState.scheduleTypeId.required') {{ $t('common:required') }}
          fi-select(
            v-model.number = 'validationState.scheduleTypeId.$model'
            :options       = 'optionsFromClassifier("scheduleTypes")'
            :class         = '{ "is-invalid": validationState.scheduleTypeId.$error }'
            required
            sm
          )
        td: fi-switch(v-model = 'productData.scheduleTypeIdOverride')
        td(v-if = 'leasing')
      tr
        td {{ $t('period') }}
        td: fi-form-field.mb-0(:state = '!validationState.periodGroup.$anyError')
          template(#error)
            .row
              .col-md-3
                .error-message(v-if = '!validationState.periodUnitTypeId.required') {{ $t('common:required') }}
              .col-md-3
                .error-message(v-if = '!validationState.period.min') {{ $t('minError', { value: validationState.periodMin.$model }) }}
                .error-message(v-if = '!validationState.period.max') {{ $t('maxError', { value: validationState.periodMax.$model }) }}
                .error-message(v-if = '!validationState.period.required') {{ $t('common:required') }}
              .col-md-3
                .error-message(v-if = '!validationState.periodMin.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.periodMin.min') {{ $t('minError', { value: validationState.periodMin.$params.min.min }) }}
                .error-message(v-if = '!validationState.periodMin.max') {{ $t('maxError', { value: validationState.periodMax.$model }) }}
              .col-md-3
                .error-message(v-if = '!validationState.periodMax.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.periodMax.min') {{ $t('minError', { value: validationState.periodMin.$model }) }}
          .row
            .col-md-3
              fi-select(
                v-model.number = 'validationState.periodUnitTypeId.$model'
                :options     = 'periodUnitOptions'
                :class         = '{ "is-invalid": validationState.periodUnitTypeId.$error }'
                required
                sm
              )
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:default') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.period.$model'
                :class         = '{ "is-invalid": validationState.period.$error }'
              )
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:min') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.periodMin.$model'
                :class         = '{ "is-invalid": validationState.periodMin.$error }'
              )
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:max') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.periodMax.$model'
                :class         = '{ "is-invalid": validationState.periodMax.$error }'
              )
        td: fi-switch(v-model = 'productData.periodOverride')
        td(v-if = 'leasing')
      tr(v-if = '!overdraft')
        td {{ $t('extensionPeriod') }}
        td: fi-form-field.mb-0(:state = '!validationState.automaticExtensionGroup.$anyError')
          template(#error)
            .row
              .col-md-3
              .col-md-3
                .error-message(v-if = '!validationState.automaticExtensionDaysMin.max') {{ $t('maxError', { value: validationState.automaticExtensionDaysMax.$model }) }}
              .col-md-3
                .error-message(v-if = '!validationState.automaticExtensionDaysMax.min') {{ $t('minError', { value: validationState.automaticExtensionDaysMin.$model }) }}
              .col-md-3
                .error-message(v-if = '!validationState.automaticExtensionDaysSliderStep.required && validationState.automaticExtensionDaysSliderStep.$error') {{ $t('common:required') }}

          .row
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('maxExtensions') }}
              input.form-control.form-control-sm(v-model.number = 'productData.automaticExtensionNumberMax')

            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:min') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.automaticExtensionDaysMin.$model'
                :disabled      = '!productData.automaticExtensionNumberMax'
                :class         = '{ "is-invalid": validationState.automaticExtensionDaysMin.$error }'
              )

            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:max') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.automaticExtensionDaysMax.$model'
                :disabled      = '!productData.automaticExtensionNumberMax'
                :class         = '{ "is-invalid": validationState.automaticExtensionDaysMax.$error }'
              )

            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('step') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.automaticExtensionDaysSliderStep.$model'
                :disabled      = '!productData.automaticExtensionNumberMax || productData.automaticExtensionDaysMin === productData.automaticExtensionDaysMax'
                :class         = '{ "is-invalid": validationState.automaticExtensionDaysSliderStep.$error }'
              )
        td
        td(v-if = 'leasing')
      tr
        td {{ $t('amount') }}
        td: fi-form-field.mb-0(:state = '!validationState.amountGroup.$anyError')
          template(#error)
            .row
              .col-md
                .error-message(v-if = '!validationState.currencyId.required') {{ $t('common:required') }}
              .col-md
                .error-message(v-if = '!validationState.amount.min') {{ $t('minError', { value: validationState.amountMin.$model }) }}
                .error-message(v-if = '!validationState.amount.max') {{ $t('maxError', { value: validationState.amountMax.$model }) }}
                .error-message(v-if = '!validationState.amount.required') {{ $t('common:required') }}
              .col-md
                .error-message(v-if = '!validationState.amountMin.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.amountMin.max') {{ $t('maxError', { value: validationState.amountMax.$model }) }}
              .col-md
                .error-message(v-if = '!validationState.amountMax.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.amountMax.min') {{ $t('minError', { value: validationState.amountMin.$model }) }}
              .col-md
                .error-message(v-if = '!validationState.amountSliderStep.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.amountSliderStep.max') {{ $t('maxError', { value: validationState.amountSliderStep.$params.max.param(validationState.$model) }) }}
                .error-message(v-if = '!validationState.amountSliderStep.steps') {{ $t('stepsError')}}
          .row
            .col
              fi-select(
                v-model.number = 'validationState.currencyId.$model'
                :options       = 'optionsFromClassifier("currencies")'
                :class         = '{ "is-invalid": validationState.currencyId.$error }'
                required
                sm
              )
            .input-group.input-group-sm.col-md
              .input-group-prepend
                span.input-group-text {{ $t('common:default') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.amount.$model'
                :class         = '{ "is-invalid": validationState.amount.$error }'
              )
            .input-group.input-group-sm.col-md
              .input-group-prepend
                span.input-group-text {{ $t('common:min') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.amountMin.$model'
                :class         = '{ "is-invalid": validationState.amountMin.$error }'
              )
            .input-group.input-group-sm.col-md
              .input-group-prepend
                span.input-group-text {{ $t('common:max') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.amountMax.$model'
                :class         = '{ "is-invalid": validationState.amountMax.$error }'
              )
            .input-group.input-group-sm.col-md
              .input-group-prepend
                span.input-group-text {{ $t('step') }}
              input.form-control.form-control-sm(
                v-model.number = 'validationState.amountSliderStep.$model'
                :class         = '{ "is-invalid": validationState.amountSliderStep.$error }'
              )
        td
          fi-switch(v-model = 'productData.currencyIdOverride')
        td(v-if = 'leasing'): fi-switch(v-model = 'productData.principalWithVat')
      tr
        td {{ $t('baseRates') }}
        td
          fi-multiselect(
            v-model          = 'selectedInterestRates'
            :options         = 'optionsForInterestRates'
            track-by         = 'value'
            label            = 'text'
            multiple
            hide-selected
            sm
          )
            template(v-slot:tag="{ option, remove }")
              div.multiselect__tag
                input.multiselect__tag-checkbox(
                  type     = 'checkbox'
                  :checked = 'option.default'
                  @change  = 'handleChangeDefault(option)'
                )
                span.multiselect__tag-label {{ option['text'] }}
                i.multiselect__tag-icon(@click = "remove(option)")
        td
      tr
        td {{ $t('loanLimitRate') }}
        td: fi-form-field.mb-0
          percent-input.form-control.form-control-sm(v-model.number = 'productData.loanLimitRate')
        td
        td(v-if = 'leasing')
      template(v-if = 'leasing')
        tr
          td {{ $t('upfrontAmountCalculationType') }}
          td: fi-form-field.mb-0(:state = '!validationState.upfrontAmountCalculationType.$error')
            template(#error)
              .error-message(v-if = '!validationState.upfrontAmountCalculationType.required') {{ $t('common:required') }}
            fi-select(
              v-model.number = 'validationState.upfrontAmountCalculationType.code.$model'
              :options       = 'leasingCalculationTypeOptions'
              :class         = '{ "is-invalid": validationState.upfrontAmountCalculationType.$error }'
              required
              sm
            )
          td: fi-switch(v-model = 'productData.upfrontAmountCalculationTypeIdOverride')
          td
        tr
          td {{ $t('upfrontAmount') }}
          td: fi-form-field.mb-0(:state = '!validationState.upfrontAmountGroup.$anyError')
            template(#error)
              .row
                .col-md-4
                  .error-message(v-if = '!validationState.upfrontAmount.required') {{ $t('common:required') }}
                .col-md-4
                  .error-message(v-if = '!validationState.upfrontAmountMin.max && validationState.upfrontAmountMin.$error') {{ $t('maxError', { value: validationState.upfrontAmountMax.$model }) }}
                .col-md-4
                  .error-message(v-if = '!validationState.upfrontAmountMax.min && validationState.upfrontAmountMax.$error') {{ $t('minError', { value: validationState.upfrontAmountMin.$model }) }}
            .row
              .input-group.col-md-4.input-group-sm
                .input-group-prepend
                  span.input-group-text {{ $t('common:default') }}
                percent-input.form-control.form-control-sm(
                  v-if           = 'percentageUpfrontAmount'
                  v-model.number = 'validationState.upfrontAmount.$model'
                  :class         = '{ "is-invalid": validationState.upfrontAmount.$error }'
                )
                input.form-control.form-control-sm(
                  v-else
                  v-model.number = 'validationState.upfrontAmount.$model'
                  :class         = '{ "is-invalid": validationState.upfrontAmount.$error }'
                )
              .input-group.col-md-4.input-group-sm
                .input-group-prepend
                  span.input-group-text {{ $t('common:min') }}
                input.form-control.form-control-sm(
                  v-model.number = 'validationState.upfrontAmountMin.$model'
                  :class         = '{ "is-invalid": validationState.upfrontAmountMin.$error }'
                )
              .input-group.col-md-4.input-group-sm
                .input-group-prepend
                  span.input-group-text {{ $t('common:max') }}
                input.form-control.form-control-sm(
                  v-model.number = 'validationState.upfrontAmountMax.$model'
                  :class         = '{ "is-invalid": validationState.upfrontAmountMax.$error }'
                )
          td: fi-switch(v-model = 'productData.upfrontAmountOverride')
          td
        tr
          td {{ $t('residualAmountCalculationType') }}
          td: fi-form-field.mb-0(:state = '!validationState.residualAmountCalculationType.$error')
            template(#error)
              .error-message(v-if = '!validationState.residualAmountCalculationType.required') {{ $t('common:required') }}
            fi-select(
              v-model.number = 'validationState.residualAmountCalculationType.code.$model'
              :options       = 'leasingCalculationTypeOptions'
              :class         = '{ "is-invalid": validationState.residualAmountCalculationType.$error }'
              required
              sm
            )
          td: fi-switch(v-model = 'productData.residualAmountCalculationTypeIdOverride')
          td
        tr
          td {{ $t('residualAmount') }}
          td: fi-form-field.mb-0(:state = '!validationState.residualAmountGroup.$anyError')
            template(#error)
              .row
                .col-md-4
                  .error-message(v-if = '!validationState.residualAmount.required') {{ $t('common:required') }}
                .col-md-4
                  .error-message(v-if = '!validationState.residualAmountMin.max && validationState.residualAmountMin.$error') {{ $t('maxError', { value: validationState.residualAmountMax.$model }) }}
                .col-md-4
                  .error-message(v-if = '!validationState.residualAmountMax.min && validationState.residualAmountMax.$error') {{ $t('minError', { value: validationState.residualAmountMin.$model }) }}
            .row
              .input-group.col-md.input-group-sm
                .input-group-prepend
                  span.input-group-text {{ $t('common:default') }}
                percent-input.form-control.form-control-sm(
                  v-if           = 'percentageResidualAmount'
                  v-model.number = 'validationState.residualAmount.$model'
                  :class         = '{ "is-invalid": validationState.residualAmount.$error }'
                )
                input.form-control.form-control-sm(
                  v-else
                  v-model.number = 'validationState.residualAmount.$model'
                  :class         = '{ "is-invalid": validationState.residualAmount.$error }'
                )
              .input-group.col-md.input-group-sm
                .input-group-prepend
                  span.input-group-text {{ $t('common:min') }}
                input.form-control.form-control-sm(
                  v-model.number = 'validationState.residualAmountMin.$model'
                  :class         = '{ "is-invalid": validationState.residualAmountMin.$error }'
                )
              .input-group.col-md.input-group-sm
                .input-group-prepend
                  span.input-group-text {{ $t('common:max') }}
                input.form-control.form-control-sm(
                  v-model.number = 'validationState.residualAmountMax.$model'
                  :class         = '{ "is-invalid": validationState.residualAmountMax.$error }'
                )
          td: fi-switch(v-model = 'productData.residualAmountOverride')
          td
      template(v-if = 'overdraft')
        tr
          td {{ $t('paymentDay') }}
          td: fi-form-field.mb-0(:state = '!validationState.invoicingDay.$error')
            template(#error)
              .error-message(v-if = '!validationState.invoicingDay.required') {{ $t('common:required') }}
              .error-message(v-if = '!validationState.invoicingDay.maxValue') {{ $t('maxError', { value: validationState.invoicingDay.$params.maxValue.max }) }}
            input.form-control.form-control-sm(
              v-model.number = 'validationState.invoicingDay.$model'
              :class         = '{ "is-invalid": validationState.invoicingDay.$error }'
            )
          td
          td(v-if = 'leasing')
        tr
          td {{ $t('paymentTerms') }}
          td: fi-form-field.mb-0(:state = '!validationState.invoicePaymentTerms.$error')
            template(#error)
              .error-message(v-if = '!validationState.invoicePaymentTerms.required') {{ $t('common:required') }}
            input.form-control.form-control-sm(
              v-model.number = 'validationState.invoicePaymentTerms.$model'
              :class         = '{ "is-invalid": validationState.invoicePaymentTerms.$error }'
            )
          td
          td(v-if = 'leasing')
      tr
        td {{ $t('interestRate') }}
        td: fi-form-field.mb-0(:state = '!validationState.interestRateGroup.$anyError')
          template(#error)
            .row
              .col-md
                .error-message(v-if = '!validationState.dayCountConventionId.required') {{ $t('common:required') }}
              .col-md-3
                .error-message(v-if = '!validationState.interestRate.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.interestRate.min') {{ formatRangeError('minError', validationState.interestRateMin.$model, true) }}
                .error-message(v-if = '!validationState.interestRate.max') {{ formatRangeError('maxError', validationState.interestRateMax.$model, true) }}
              .col-md-3
                .error-message(v-if = '!validationState.interestRateMin.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.interestRateMin.max && validationState.interestRateMin.$error') {{ formatRangeError('maxError', validationState.interestRateMax.$model, true) }}
              .col-md-3
                .error-message(v-if = '!validationState.interestRateMax.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.interestRateMax.min && validationState.interestRateMax.$error') {{ formatRangeError('minError', validationState.interestRateMin.$model, true) }}
          .row
            .col-md
              fi-select(
                v-model.number = 'validationState.dayCountConventionId.$model'
                :options       = 'optionsFromClassifier("dayCountConventions")'
                :class         = '{ "is-invalid": validationState.dayCountConventionId.$error }'
                required
                sm
              )
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:default') }}
              percent-input.form-control.form-control-sm(
                v-model.number = 'validationState.interestRate.$model'
                :class         = '{ "is-invalid": validationState.interestRate.$error }'
              )
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:min') }}
              percent-input.form-control.form-control-sm(
                v-model.number = 'validationState.interestRateMin.$model'
                :class         = '{ "is-invalid": validationState.interestRateMin.$error }'
              )
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:max') }}
              percent-input.form-control.form-control-sm(
                v-model.number = 'validationState.interestRateMax.$model'
                :class         = '{ "is-invalid": validationState.interestRateMax.$error }'
              )
        td
          fi-switch(v-model = 'productData.dayCountConventionIdOverride')
        td(v-if = 'leasing')
          fi-switch(
            v-model   = 'productData.interestWithVat'
            :disabled = '!operational'
          )
      tr
        td {{ $t('penaltyRate') }}
        td: fi-form-field.mb-0(:state = '!validationState.penaltyInterestRateGroup.$anyError')
          template(#error)
            .row
              .col-md
                .error-message(v-if = '!validationState.penaltyDayCountConventionId.required') {{ $t('common:required') }}
              .col-md-3
                .error-message(v-if = '!validationState.penaltyInterestRate.min') {{ formatRangeError('minError', validationState.penaltyInterestRateMin.$model, true) }}
                .error-message(v-if = '!validationState.penaltyInterestRate.max') {{ formatRangeError('maxError', validationState.penaltyInterestRateMax.$model, true) }}
              .col-md-3
                .error-message(v-if = '!validationState.penaltyInterestRateMin.max && validationState.penaltyInterestRateMin.$error') {{ formatRangeError('maxError', validationState.penaltyInterestRateMax.$model, true) }}
              .col-md-3
                .error-message(v-if = '!validationState.penaltyInterestRateMax.min && validationState.penaltyInterestRateMax.$error') {{ formatRangeError('minError', validationState.penaltyInterestRateMin.$model, true) }}


                .col-md-3
                .error-message(v-if = '!validationState.interestRateMin.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.interestRateMin.max && validationState.interestRateMin.$error') {{ formatRangeError('maxError', validationState.interestRateMax.$model, true) }}
              .col-md-3
                .error-message(v-if = '!validationState.interestRateMax.required') {{ $t('common:required') }}
                .error-message(v-if = '!validationState.interestRateMax.min && validationState.interestRateMax.$error') {{ formatRangeError('minError', validationState.interestRateMin.$model, true) }}
          .row
            .col-md
              fi-select(
                v-model.number = 'validationState.penaltyDayCountConventionId.$model'
                :options       = 'optionsFromClassifier("dayCountConventions")'
                :class         = '{ "is-invalid": validationState.penaltyDayCountConventionId.$error }'
                required
                sm
              )
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:default') }}
              percent-input.form-control.form-control-sm(v-model.number = 'validationState.penaltyInterestRate.$model')
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:min') }}
              percent-input.form-control.form-control-sm(v-model.number = 'validationState.penaltyInterestRateMin.$model')
            .input-group.col-md-3.input-group-sm
              .input-group-prepend
                span.input-group-text {{ $t('common:max') }}
              percent-input.form-control.form-control-sm(v-model.number = 'validationState.penaltyInterestRateMax.$model')
        td
          fi-switch(v-model = 'productData.penaltyDayCountConventionIdOverride')
        td(v-if = 'leasing')
      tr
        td {{ $t('penaltyRateCalculation') }}
        td
          .custom-control.custom-checkbox
            input.custom-control-input(
              v-model = 'productData.includeAllInvoicesToPenalty'
              id      = 'penaltyRateCalculation'
              type    = 'checkbox'
              :value  = 'true'
            )
            label.custom-control-label(for = 'penaltyRateCalculation') {{ $t('penaltyRateCalculationPh') }}
        td
        td(v-if = 'leasing')
      tr
        td {{ $t('firstInterestPayment') }}
        td: fi-form-field.mb-0(:state = '!validationState.firstInterestPayment.$error')
          template(#error)
            .error-message(v-if = '!validationState.firstInterestPayment.required') {{ $t('common:required') }}
          input.form-control.form-control-sm(
            v-model.number = 'validationState.firstInterestPayment.$model'
            :placeholder   = '$t("firstInterestPaymentPh")'
            :class         = '{ "is-invalid": validationState.firstInterestPayment.$error }'
          )
        td: fi-switch(v-model = 'productData.firstInterestPaymentOverride')
        td(v-if = 'leasing')
      tr
        td {{ $t('interestPaymentPeriod') }}
        td: fi-form-field.mb-0(
          :state = '!validationState.interestPaymentPeriodId.$error'
          :disabled = 'isDayPeriod'
        )
          template(#error)
            .error-message(v-if = '!validationState.interestPaymentPeriodId.required') {{ $t('common:required') }}
            .error-message(v-if = '!validationState.interestPaymentPeriodId.tenor') {{ $t('tenorError') }}
          fi-select(
            v-model.number = 'validationState.interestPaymentPeriodId.$model'
            :options       = 'optionsFromClassifier("paymentPeriodTypes")'
            :class         = '{ "is-invalid": validationState.interestPaymentPeriodId.$error }'
            required
            sm
          )
        td: fi-switch(
          v-model   = 'productData.interestPaymentPeriodIdOverride'
          :disabled = 'isInterestTenor && isDayPeriod'
        )
        td(v-if = 'leasing')
      template(v-if = 'overdraft')
        tr
          td {{ $t('principalPaymentPeriod') }}
          td: fi-form-field.mb-0(
            :state    = '!validationState.principalPaymentPeriodId.$error'
            :disabled = 'isDayPeriod'
          )
            template(#error)
              .error-message(v-if = '!validationState.principalPaymentPeriodId.required') {{ $t('common:required') }}
              .error-message(v-if = '!validationState.principalPaymentPeriodId.tenor') {{ $t('tenorError') }}
              .error-message(v-if = '!validationState.upfrontAmountCalculationType.required') {{ $t('common:required') }}
            .row
              .col
                fi-select(
                  v-model.number = 'validationState.principalPaymentPeriodId.$model'
                  :options       = 'principalPaymentPeriods'
                  :class         = '{ "is-invalid": validationState.principalPaymentPeriodId.$error }'
                  required
                  sm
                )
              .col
                fi-select(
                  v-model.number = 'validationState.principalPaybackAmountCalculationType.code.$model'
                  :options       = 'calculationTypeOptions'
                  :class         = '{ "is-invalid": validationState.principalPaybackAmountCalculationType.$error }'
                  required
                  sm
                )
              .col
                percent-input.form-control.form-control-sm(
                  v-if           = 'percentagePrincipalPaybackAmount'
                  v-model.number = 'validationState.principalPaybackAmount.$model'
                  :class         = '{ "is-invalid": validationState.principalPaybackAmount.$error }'
                )
                input.form-control.form-control-sm(
                  v-else
                  v-model.number = 'validationState.principalPaybackAmount.$model'
                  :class         = '{ "is-invalid": validationState.principalPaybackAmount.$error }'
                )
      template(v-if = '!(overdraft || isBulletScheduleType)')
        tr
          td {{ $t('firstPrincipalPayment') }}
          td: fi-form-field.mb-0(:state = '!validationState.firstPrincipalPayment.$error')
            template(#error)
              .error-message(v-if = '!validationState.firstPrincipalPayment.required') {{ $t('common:required') }}
            input.form-control.form-control-sm(
              v-model.number = 'validationState.firstPrincipalPayment.$model'
              :placeholder   = '$t("firstPrincipalFieldPh")'
              :class         = '{ "is-invalid": validationState.firstPrincipalPayment.$error }'
            )
          td: fi-switch(v-model = 'productData.firstPrincipalPaymentOverride')
          td(v-if = 'leasing')
        tr
          td {{ $t('principalPaymentPeriod') }}
          td: fi-form-field.mb-0(
            :state    = '!validationState.principalPaymentPeriodId.$error'
            :disabled = 'isDayPeriod'
          )
            template(#error)
              .error-message(v-if = '!validationState.principalPaymentPeriodId.required') {{ $t('common:required') }}
              .error-message(v-if = '!validationState.principalPaymentPeriodId.tenor') {{ $t('tenorError') }}
            fi-select(
              v-model.number = 'validationState.principalPaymentPeriodId.$model'
              :options       = 'principalPaymentPeriods'
              :class         = '{ "is-invalid": validationState.principalPaymentPeriodId.$error }'
              required
              sm
            )
          td: fi-switch(
            v-model   = 'productData.principalPaymentPeriodIdOverride'
            :disabled = 'isDayPeriod'
          )
          td(v-if = 'leasing')
      template(v-if = 'isFinland')
        tr
          td {{ $t('penaltyRateChangeDays') }}
          td: fi-form-field.mb-0(:state = '!validationState.automaticPenaltyRateChangeDays.$error')
            template(#error)
              .error-message(v-if = '!validationState.automaticPenaltyRateChangeDays.required') {{ $t('common:required') }}
            input.form-control.form-control-sm(
              v-model.number = 'validationState.automaticPenaltyRateChangeDays.$model'
              :class         = '{ "is-invalid": validationState.automaticPenaltyRateChangeDays.$error }'
            )
          td
          td(v-if = 'leasing')
        tr
          td {{ $t('penaltyRateChangeRate') }}
          td: fi-form-field.mb-0(:state = '!validationState.automaticPenaltyRateChangeRate.$error')
            template(#error)
              .error-message(v-if = '!validationState.automaticPenaltyRateChangeRate.required') {{ $t('common:required') }}
            percent-input.form-control.form-control-sm(
              v-model.number = 'validationState.automaticPenaltyRateChangeRate.$model'
              :class         = '{ "is-invalid": validationState.automaticPenaltyRateChangeRate.$error }'
            )
          td
          td(v-if = 'leasing')
      tr
        td.fee-row(:colspan = 'leasing ? 4 : 3')
          .fee-title {{ $t('fees') }}
          table.table.fee-table
            thead: tr
              th {{ $t('feeTableTitle') }}
              th {{ $t('feeCalculationMethod') }}
              th {{ $t('common:default') }}
              th {{ $t('common:min') }}
              th {{ $t('common:max') }}
              th {{ $t('changesAllowed') }}
              th(v-if = 'leasing') {{ $t('vat') }}
            tbody
              product-fee-item.animated.fadeIn(
                v-for         = '(fee, index) in validationState.fees.$each.$iter'
                :key          = 'index'
                :fee          = 'fee'
                :removable    = 'validationState.fees.$model.length > validationState.fees.$params.minLength.min'
                :product-data = 'productData'
                :leasing      = 'leasing'
                :type-options = 'feeTypeOptions'
                :required     = 'feeRequired(fee)'
                :operational  = 'operational'
                :fee-configs   = 'feeConfigs'
                @remove-fee   = 'removeFee(index)'
              )
      tr(v-if = 'feeTypeOptions.length')
        td.border-top-0.text-center(colspan='3')
          button.btn.btn-link(@click.prevent = 'addFee()') {{ $t('addFee') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMultiselect from '@/components/FiMultiselect'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import PercentInput from '@/components/PercentInput'
import { formatRangeError } from '@/mixins'
import ProductFeeItem from '@/views/admin/products/ProductFeeItem'
import differenceWith from 'lodash/differenceWith'
import { mapGetters, mapState, mapActions } from 'vuex'
import { feePercentage } from '@/helpers'
import { fee } from '@/helpers/feeUtils'
import { GUARANTEE_FEE } from './variables'

export default {
  name: 'product-attributes',

  components: {
    ProductFeeItem,
    FiMultiselect,
    FiSwitch,
    PercentInput,
    FiFormField,
    FiSelect
  },

  mixins: [formatRangeError],

  props: {
    productData: {
      type: Object,
      required: true
    },
    validationState: {
      type: Object,
      required: true
    },
    overdraft: {
      type: Boolean,
      default: false
    },
    leasing: {
      type: Boolean,
      default: false
    },
    loan: {
      type: Boolean,
      default: false
    },
    operational: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('interests', ['interestRates', 'variableInterests']),
    ...mapState('products', ['feeConfigs']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByName', 'classifierList']),
    ...mapState('settings', ['settings']),
    percentageResidualAmount () {
      return feePercentage(this.productData?.residualAmountCalculationType?.code)
    },
    selectedInterestRates: {
      get () {
        return this.productData?.productInterestDefinitionDtos?.map((variableInterestRate) => ({ value: variableInterestRate.variableInterestId, text: this.interestRatesById(variableInterestRate.variableInterestId), default: variableInterestRate.default, productId: variableInterestRate.productId, id: variableInterestRate.id }))
      },
      set (variableInterestRates) {
        this.productData.productInterestDefinitionDtos = variableInterestRates.map((variableInterestRate) => ({ variableInterestId: variableInterestRate.value, default: variableInterestRate.default, productId: variableInterestRate.productId, id: variableInterestRate.id }))
      }
    },
    optionsForInterestRates () {
      // const interestRates = this.variableInterests.map((interestRates) => ({ text: interestRates.name, value: interestRates.id, default: interestRates.isEnabled, productId: Number(this.$route.params.id) }))
      const interestRates = this.variableInterests.map((interestRates) => this.$route.params.id ? ({ text: interestRates.name, value: interestRates.id, default: false, productId: Number(this.$route.params.id) }) : ({ text: interestRates.name, value: interestRates.id, default: false }))
      return interestRates
    },
    calculationTypeOptions () {
      const feeCalculationTypePossible = this.feeConfigs.PRODUCT_ATTRIBUTE
      return this.optionsFromClassifier('feeCalculationType', true)
        .filter(({ value }) => feeCalculationTypePossible?.includes(value) ?? value)
    },
    percentagePrincipalPaybackAmount () {
      return feePercentage(this.productData.principalPaybackAmountCalculationType.code)
    },
    percentageUpfrontAmount () {
      return feePercentage(this.productData?.upfrontAmountCalculationType?.code)
    },
    principalPaymentPeriods () {
      return this.optionsFromClassifier('paymentPeriodTypes').filter(period => period.value >= this.productData.interestPaymentPeriodId)
    },
    isBulletScheduleType () {
      return this.classifierById('scheduleTypes', this.productData.scheduleTypeId).name === 'BULLET'
    },
    hasAutomaticExtensionFee () {
      return this.productData.fees.some(({ feeType }) => /AUTO_EXTENSION/i.test(feeType.code))
    },
    periodUnitOptions () {
      return Object.values(this.classifierList('periodUnitType'))
        .filter(({ productGroups }) => productGroups.includes('LOAN'))
        .map(({ id, human }) => ({ text: human, value: id }))
    },
    isInterestTenor () {
      return /TENOR/i.test(this.classifierById('paymentPeriodTypes', this.productData.interestPaymentPeriodId).code)
    },
    isPrincipalTenor () {
      return /TENOR/i.test(this.classifierById('paymentPeriodTypes', this.productData.principalPaymentPeriodId).code)
    },
    isDayPeriod () {
      return /DAY/i.test(this.classifierById('periodUnitType', this.productData.periodUnitTypeId).code)
    },
    isFinland () {
      return /fi/i.test(this.settings.country)
    },
    feeTypeOptions () {
      return differenceWith(
        this.optionsFromClassifier('feeTypeEntity', true),
        this.validationState.fees.$model,
        (option, fee) => option.value === fee.feeType.code)
        .filter((el) => {
          if (!this.overdraft) return el.text !== GUARANTEE_FEE
          else return el
        })
    },
    leasingCalculationTypeOptions () {
      const feeCalculationTypePossible = this.feeConfigs.CUSTOM_FEE
      return this.optionsFromClassifier('feeCalculationType', true)
        .filter(({ value }) => feeCalculationTypePossible?.includes(value) ?? value)
    }
  },

  watch: {
    'productData.periodUnitTypeId' () {
      if (this.isDayPeriod) {
        this.productData.interestPaymentPeriodId = this.classifierByName('paymentPeriodTypes', 'TENOR')?.id
        this.productData.principalPaymentPeriodId = this.classifierByName('paymentPeriodTypes', 'TENOR')?.id
        this.productData.interestPaymentPeriodIdOverride = false
        this.productData.principalPaymentPeriodIdOverride = false
      }
    },
    'productData.interestPaymentPeriodId' (val) {
      if (this.isBulletScheduleType) return
      if (this.productData.principalPaymentPeriodId < val) this.productData.principalPaymentPeriodId = val
      if (this.isInterestTenor && this.isDayPeriod) {
        this.productData.interestPaymentPeriodIdOverride = false
      }
    },
    'productData.principalPaymentPeriodId' () {
      if (this.isPrincipalTenor && this.isDayPeriod) {
        this.productData.principalPaymentPeriodIdOverride = false
      }
    },
    'productData.principalWithVat' (value) {
      if (!this.operational) {
        return
      }

      this.productData.interestWithVat = value
    },
    'productData.interestWithVat' (value) {
      if (!this.operational) {
        return
      }

      this.productData.principalWithVat = value
    },
    'productData.automaticExtensionNumberMax': {
      handler (value) {
        const automaticExtensionFeeIdx = this.productData.fees.findIndex(({ feeType }) => feeType.code === 'AUTO_EXTENSION')
        if (value > 0) {
          !~automaticExtensionFeeIdx && this.addFee(fee({ leasing: this.leasing, feeType: 'AUTO_EXTENSION', feeCalculationType: 'FIXED_SUM' }))
        } else {
          Boolean(~automaticExtensionFeeIdx) && this.removeFee(automaticExtensionFeeIdx)
        }
      },
      immediate: true
    }
  },

  async mounted () {
    if (this.overdraft) {
      this.productData.scheduleTypeId = this.classifierByName('scheduleTypes', 'OVERDRAFT')?.id
    }

    if (this.isBulletScheduleType) {
      this.productData.principalPaymentPeriodId = this.classifierByName('paymentPeriodTypes', 'TENOR')?.id
    }
    await this.loadVariableInterests()
  },

  methods: {
    ...mapActions('interests', ['loadVariableInterests']),
    addFee (newFee = fee({ leasing: this.leasing })) {
      this.productData.fees = [...this.productData.fees, newFee]
    },
    interestRatesById (id) {
      return this.variableInterests.find((rate) => rate.id === id)?.name
    },
    removeFee (id) {
      if (!id) return
      this.productData.fees.splice(id, 1)
    },
    feeRequired (fee) {
      return [...Object.keys(this.feeConfigs), 'AUTO_EXTENSION'].some(feeType => new RegExp(feeType, 'i').test(fee.feeType.code.$model))
    },
    handleChangeDefault (option) {
      if (option.default) {
        this.productData.productInterestDefinitionDtos.find((interest) => interest.id === option.id).default = false
      } else {
        this.productData.productInterestDefinitionDtos.find((interest) => interest.id === option.id).default = true
        this.productData.productInterestDefinitionDtos.map((interest) => {
          if (interest.id !== option.id) {
            interest.default = false
          }
        })
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.product-attributes {
  .fee-row {
    padding: 0.75rem 0;

    .fee-title {
      padding: 0.75rem;
    }

    .fee-table {
      & /deep/ thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
    }
  }
}
</style>


<i18n>
en:
  period:                        "Agreement length"
  extensionPeriod:               "Automatic extension"
  amount:                        "Amount"
  step:                          "Step"
  currency:                      "Currency"
  interestRate:                  "Loan interest"
  dayCountConvention:            "Loan day count convention"
  penaltyRate:                   "Penalty interest"
  penaltyDayCountConvention:     "Penalty day count convention"
  scheduleType:                  "Schedule type"
  firstPrincipalPayment:          "First principal payment (months)"
  firstPrincipalFieldPh:          "Enter first principal payment"
  principalPaymentPeriod:        "Principal payment period"
  firstInterestPayment:           "First interest payment (months)"
  firstInterestPaymentPh:         "Enter first interest payment"
  interestPaymentPeriod:         "Interest payment period"
  loanLimitRate:                 "Loan limit rate"
  paymentDay:                    "Invoice day of the month"
  paymentTerms:                  "Invoice payment terms (days)"
  fees:                          "Product fees"
  feeMethod:                     "Fee calculation method"
  feeName:                       "Fee type name"
  addFee:                        "+ Add new fee"
  minError:                      "Value should be greater or equal to {{ value }}"
  maxError:                      "Value should be less or equal to {{ value }}"
  tenorError:                    "Field should be TENOR"
  maxExtensions:                 "Max nr of extensions"
  penaltyRateChangeDays:         "Automatic penalty rate change (days)"
  penaltyRateChangeRate:         "Automatic penalty rate change (%)"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  vat:                           "VAT"
  feeTableTitle:                 "Fee name"
  feeCalculationMethod:          "Calculation method"
  stepsError:                    "Number of steps should be integer"
  penaltyRateCalculation:        "Penalty interest calculation"
  penaltyRateCalculationPh:      "Include all invoices in debt"
  baseRates:                     "Base rates"
et:
  period:                        "Agreement length"
  extensionPeriod:               "Automatic extension"
  amount:                        "Amount"
  step:                          "Step"
  currency:                      "Currency"
  interestRate:                  "Loan interest"
  dayCountConvention:            "Loan day count convention"
  penaltyRate:                   "Penalty interest"
  penaltyDayCountConvention:     "Penalty day count convention"
  scheduleType:                  "Schedule type"
  firstPrincipalPayment:          "First principal payment (months)"
  firstPrincipalFieldPh:          "Enter first principal payment"
  principalPaymentPeriod:        "Principal payment period"
  firstInterestPayment:           "First interest payment (months)"
  firstInterestPaymentPh:         "Enter first interest payment"
  interestPaymentPeriod:         "Interest payment period"
  loanLimitRate:                 "Loan limit rate"
  paymentDay:                    "Invoice day of the month"
  paymentTerms:                  "Invoice payment terms (days)"
  fees:                          "Product fees"
  feeMethod:                     "Fee calculation method"
  feeName:                       "Fee type name"
  addFee:                        "+ Add new fee"
  minError:                      "Value should be greater or equal to {{ value }}"
  maxError:                      "Value should be less or equal to {{ value }}"
  tenorError:                    "Field should be TENOR"
  maxExtensions:                 "Max nr of extensions"
  penaltyRateChangeDays:         "Automatic penalty rate change (days)"
  penaltyRateChangeRate:         "Automatic penalty rate change (%)"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  vat:                           "VAT"
  feeTableTitle:                 "Fee name"
  feeCalculationMethod:          "Calculation method"
  stepsError:                    "Number of steps should be integer"
  penaltyRateCalculation:        "Penalty interest calculation"
  penaltyRateCalculationPh:      "Include all invoices in debt"
  baseRates:                     "Base rates"
ru:
  period:                        "Agreement length"
  extensionPeriod:               "Automatic extension"
  amount:                        "Amount"
  step:                          "Step"
  currency:                      "Currency"
  interestRate:                  "Loan interest"
  dayCountConvention:            "Loan day count convention"
  penaltyRate:                   "Penalty interest"
  penaltyDayCountConvention:     "Penalty day count convention"
  scheduleType:                  "Schedule type"
  firstPrincipalPayment:          "First principal payment (months)"
  firstPrincipalFieldPh:          "Enter first principal payment"
  principalPaymentPeriod:        "Principal payment period"
  firstInterestPayment:           "First interest payment (months)"
  firstInterestPaymentPh:         "Enter first interest payment"
  interestPaymentPeriod:         "Interest payment period"
  loanLimitRate:                 "Loan limit rate"
  paymentDay:                    "Invoice day of the month"
  paymentTerms:                  "Invoice payment terms (days)"
  fees:                          "Product fees"
  feeMethod:                     "Fee calculation method"
  feeName:                       "Fee type name"
  addFee:                        "+ Add new fee"
  minError:                      "Value should be greater or equal to {{ value }}"
  maxError:                      "Value should be less or equal to {{ value }}"
  tenorError:                    "Field should be TENOR"
  maxExtensions:                 "Max nr of extensions"
  penaltyRateChangeDays:         "Automatic penalty rate change (days)"
  penaltyRateChangeRate:         "Automatic penalty rate change (%)"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  vat:                           "VAT"
  feeTableTitle:                 "Fee name"
  feeCalculationMethod:          "Calculation method"
  stepsError:                    "Number of steps should be integer"
  penaltyRateCalculation:        "Penalty interest calculation"
  penaltyRateCalculationPh:      "Include all invoices in debt"
  baseRates:                     "Base rates"
</i18n>
