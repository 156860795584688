<template lang = 'pug'>
.product-scoring.table-responsive.mb-0
  table.table
    thead: tr
      th {{ $t('fieldName') }}
      th {{ $t('fieldValue') }}
      th {{ $t('changesAllowed') }}
      th {{ $t('runAutomatically') }}
    tbody
      tr
        td {{ $t('preliminaryScoringModel') }}
        td: fi-form-field.mb-0
          fi-select(
            v-model.number = 'productData.preliminaryScoringModelId'
            :options       = 'optionsFromScoringModels'
            sm
          )
        td: fi-switch(v-model = 'productData.preliminaryScoringModelIdOverride')
        td: fi-switch(v-model = 'productData.preliminaryScoringModelAutoRun')
      tr
        td {{ $t('applicantScoringModel') }}
        td: fi-form-field.mb-0
          fi-select(
            v-model.number = 'productData.applicantScoringModelId'
            :options       = 'optionsFromScoringModels'
            sm
          )
        td: fi-switch(v-model = 'productData.applicantScoringModelIdOverride')
        td: fi-switch(v-model = 'productData.applicantScoringModelAutoRun')
      tr
        td {{ $t('creditScoringModel') }}
        td: fi-form-field.mb-0
          fi-select(
            v-model.number = 'productData.creditScoringModelId'
            :options       = 'optionsFromScoringModels'
            sm
          )
        td: fi-switch(v-model = 'productData.creditScoringModelIdOverride')
        td: fi-switch(v-model = 'productData.creditScoringModelAutoRun')
</template>

<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'product-scoring',

  components: {
    FiSwitch,
    FiFormField,
    FiSelect
  },

  props: {
    productData: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapGetters('scoring', ['optionsFromScoringModels'])
  },

  mounted () {
    this.loadScoringModels()
  },

  methods: {
    ...mapActions('scoring', ['loadScoringModels'])
  }
}
</script>
