<template lang='pug'>
.product-payments
  .row
    .col
      fi-form-field(
        :label     = '$t("pdMonths")'
        label-cols = '3'
      )
        percent-input.form-control.form-control-sm(v-model = 'provisionData.pd12m')

      fi-form-field(
        v-if       = '!deposit'
        :label     = '$t("pdLifetime")'
        label-cols = '3'
      )
        percent-input.form-control.form-control-sm(v-model = 'provisionData.pdLifetime')
      fi-form-field(
        v-if       = '!deposit'
        :label     = '$t("lgd")'
        label-cols = '3'
      )
        percent-input.form-control.form-control-sm(v-model = 'provisionData.lgd')
      fi-form-field(
        v-if       = '!deposit'
        :label     = '$t("pdIncremental")'
        label-cols = '3'
      )
        percent-input.form-control.form-control-sm(v-model = 'provisionData.annualIncrementalPd')
      fi-form-field(
        v-if       = '!deposit'
        :label     = '$t("ccf")'
        label-cols = '3'
      )
        input.form-control.form-control-sm(v-model.number = 'provisionData.ccf')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import PercentInput from '@/components/PercentInput'
import { mapGetters } from 'vuex'

export default {
  name: 'product-payments',

  components: { FiFormField, PercentInput },

  props: {
    provisionData: {
      type: Object,
      required: true
    },
    deposit: {
      type: Boolean,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier'])
  }
}
</script>


<i18n>
en:
  pdMonths:               "PD 12 months"
  pdLifetime:             "PD lifetime"
  lgd:                    "LGD"
  pdIncremental:          "Incremental PD"
  ccf:                    "CCF"
et:
  pdMonths:               "PD 12 months"
  pdLifetime:             "PD lifetime"
  lgd:                    "LGD"
  pdIncremental:          "Incremental PD"
  ccf:                    "CCF"
ru:
  pdMonths:               "PD 12 months"
  pdLifetime:             "PD lifetime"
  lgd:                    "LGD"
  pdIncremental:          "Incremental PD"
  ccf:                    "CCF"
</i18n>
