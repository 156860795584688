<template lang="pug">
  .product-custom-fields
    fi-table(
      :fields          = 'fields'
      :items          = 'customFields'
      :empty-label    = '$t("notFound")'
      draggable-class = ".product-custom-fields__item"
      responsive
      draggable
      @drag           = 'updateDragged'
    )
      template(#actions)
        button.ml-auto.btn.btn-primary.btn-sm(@click.prevent = 'showAddModal = true') {{ $t('addNew') }}
      template(#default = '{ items }')
        tr.product-custom-fields__item(v-for = '(item, index) in items')
          td {{ item.fieldOrder }}
          td {{ fieldMeta[item.customFieldId].key }}
          td {{ fieldMeta[item.customFieldId].name }}
          td
            fi-switch(v-model = 'item.required')
          td
            fi-switch(v-model = 'item.enabled')
          td
            fi-switch(v-model = 'item.enabledPortal')
          td
            fi-switch(v-model = 'item.enabledApplication')
          td
            fi-switch(v-model = 'item.enabledContract')
          td.text-right
            button.btn.btn-danger(@click.prevent = 'updateFields({ index })')
              i.far.fa-trash-alt.fa-lg
    product-custom-field-modal(
      v-model = 'showAddModal'
      :field-options = 'fieldOptions'
      @save = 'updateFields'
    )
</template>


<script>
import FiTable from '@/components/FiTable'
import { mapActions, mapState } from 'vuex'
import ProductCustomFieldModal from '@/views/admin/products/ProductCustomFieldModal'
import FiSwitch from '@/components/FiSwitch'

export default {
  name: 'product-custom-fields',

  components: { FiSwitch, ProductCustomFieldModal, FiTable },

  props: {
    customFields: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    showAddModal: false
  }),

  computed: {
    ...mapState('customFields', {
      fieldOptions: (state) => state.customFields
    }),
    fields () {
      return [
        {
          key: 'order',
          label: this.$t('order')
        },
        {
          key: 'key',
          label: this.$t('key')
        },
        {
          key: 'name',
          label: this.$t('name')
        },
        {
          key: 'required',
          label: this.$t('required')
        },
        {
          key: 'enabled',
          label: this.$t('enabled')
        },
        {
          key: 'portal',
          label: this.$t('portal')
        },
        {
          key: 'application',
          label: this.$t('application')
        },
        {
          key: 'contract',
          label: this.$t('contract')
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right'
        }
      ]
    },
    fieldMeta () {
      return this.fieldOptions.reduce((res, option) => ({ ...res, [option.id]: option }), {})
    }
  },

  created () {
    this.loadCustomFields()
  },

  methods: {
    ...mapActions('customFields', ['loadCustomFields']),
    updateFields ({ done, field, index = -1, orders }) {
      this.$emit('update', { field, index, orders })
      // eslint-disable-next-line no-unused-expressions
      done?.()
    },
    updateDragged (items) {
      this.updateFields({ orders: items.filter(Boolean).map(({ customFieldId }, index) => ({ customFieldId, fieldOrder: index + 1 })) })
    }
  }
}
</script>


<i18n>
en:
  addNew:      "+ Add new"
  order:       "Order"
  key:         "Key"
  name:        "Name"
  enabled:     "Enabled"
  portal:      "Portal"
  application: "Application"
  contract:    "Contract"
  notFound:    "No custom fields"
  required:    "Required"
et:
  addNew:      "+ Add new"
  order:       "Order"
  key:         "Key"
  name:        "Name"
  enabled:     "Enabled"
  portal:      "Portal"
  application: "Application"
  contract:    "Contract"
  notFound:    "No custom fields"
  required:    "Required"
ru:
  addNew:      "+ Add new"
  order:       "Order"
  key:         "Key"
  name:        "Name"
  enabled:     "Enabled"
  portal:      "Portal"
  application: "Application"
  contract:    "Contract"
  notFound:    "No custom fields"
  required:    "Required"
</i18n>
